import request from '@/service';

// 新建采单码
export async function create_info_acquisition_code(params) {
  return request('/person-service/info_acquisition_code/create_info_acquisition_code', {
    method: 'post',
    data: params,
  });
}

// 修改采单码
export async function update_info_acquisition_code(params) {
  return request('/person-service/info_acquisition_code/update_info_acquisition_code', {
    method: 'post',
    data: params,
  });
}

// 作废采单码
export async function invalid_info_acquisition_code(params) {
  return request('/person-service/info_acquisition_code/invalid_info_acquisition_code', {
    method: 'post',
    data: params,
  });
}

// 恢复采单码
export async function renew_info_acquisition_code(params) {
  return request('/person-service/info_acquisition_code/renew_info_acquisition_code', {
    method: 'post',
    data: params,
  });
}

// 采单码详情
export async function info_acquisition_code_detail(params) {
  return request('/person-service/info_acquisition_code/info_acquisition_code_detail', {
    method: 'get',
    data: params,
  });
}

// 获取已绑定帐号列表(采单员)
export async function get_account_relation_list(params) {
  return request('/organization-service/account_relation/get_account_relation_list', {
    method: 'get',
    data: params,
  });
}

// 获取线索来源
export async function get_leads_source_code(params) {
  return request('/person-service/leads_source_code/list_all', {
    method: 'get',
    data: params,
  });
}

// 刷新采单员用户名称
export async function refresh_account_name(params) {
  return request('/person-service/info_acquisition_code/refresh_account_name', {
    method: 'post',
    data: params,
  });
}

// 获取个人信息
export async function getUserInfo(params) {
  return request('/organization-service/account_info/info', {
    method: 'get',
    data: params,
  });
}
