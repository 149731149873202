import React, { useEffect } from 'react';
import { Layout } from 'antd';
import { Switch } from 'react-router-dom';
import { renderRoutes } from 'react-router-config';
import openNewPage from '@/utils/openNewPage';
import styles from './index.module.less';
import HeaderComponent from '../commonComponent/Header';
import SiderComponent from '../commonComponent/Sider';
import BreadCrumbComponent from '../commonComponent/BreadCrumb';

const { Header, Sider, Content } = Layout;

const MainLayout = (props) => {
  const {
    location,
    chidrenRoutes,
  } = props;

  // 面包屑第一个 > 样式
  const firstbreadItemStyle = {
    marginLeft: '20px',
  };

  useEffect(() => {
    if (!window.localStorage.getItem('token')) {
      openNewPage('/', '_self');
    }
  }, []);

  // console.log('chidrenRoutes',chidrenRoutes)

  return (
    <div className={styles.container}>
      <Layout>
        <Sider width={240}>
          <SiderComponent data={chidrenRoutes} />
        </Sider>
        <Layout>
          <Header style={{height: '52px', lineHeight: '52px'}}>
            <HeaderComponent />
          </Header>
          <Content>
            <BreadCrumbComponent firstBeforeIconStyle={firstbreadItemStyle} />
            <div className={styles.contentContainer}>
              <Switch location={location}>
                {renderRoutes(chidrenRoutes)}
              </Switch>
            </div>
          </Content>
          {/* <Footer>Footer</Footer> */}
        </Layout>
      </Layout>
    </div>
  );
};

export default MainLayout
