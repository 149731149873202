import { message } from 'antd';
import * as server from './server';

export default {
  state: {
    loading: false,
    list: [],
    courseList: [], // 课程列表
    createButtonLoading: false,
    detailLoading: false,
    refreshTime: new Date().getTime(),
  },
  reducers: {
    setState(state, payload) {
      Object.keys(payload).forEach((item) => {
        state[item] = payload[item];
      });
    },
  },
  effects: () => ({
    // 机构详情
    async get_detail_by_id(payload, rootStates, cb) {
      this.setState({
        detailLoading: true,
      });
      const { code, data } = await server.get_detail_by_id(payload);
      if (code === 0) {
        this.setState({
          detailInfo: data,
        });
        cb && cb(data);
      }
      this.setState({
        detailLoading: false,
      });
    },
    // 更新机构图片
    async update_org_img(payload, rootStates, cb) {
      this.setState({
        detailLoading: true,
      });
      const { code, data } = await server.update_org_img(payload);
      if (code === 0) {
        cb && cb(data);
      }
      this.setState({
        detailLoading: false,
      });
    },
    // 更新机构基础信息
    async update_org_base_info(payload, rootStates, cb) {
      this.setState({
        detailLoading: true,
      });
      const { code, data } = await server.update_org_base_info(payload);
      if (code === 0) {
        cb && cb(data);
      }
      this.setState({
        detailLoading: false,
      });
    },
    // 更新机构荣誉图片
    async update_org_honor_img(payload, rootStates, cb) {
      this.setState({
        detailLoading: true,
      });
      const { code, data } = await server.update_org_honor_img(payload);
      if (code === 0) {
        cb && cb(data);
      }
      this.setState({
        detailLoading: false,
      });
    },
    // 更新机构环境图片
    async update_org_env_img(payload, rootStates, cb) {
      this.setState({
        detailLoading: true,
      });
      const { code, data } = await server.update_org_env_img(payload);
      if (code === 0) {
        cb && cb(data);
      }
      this.setState({
        detailLoading: false,
      });
    },
    // 移除线上售卖
    async remove_sell_online(payload, rootStates, cb) {
      this.setState({
        buttonLoading: true,
      });
      const { code } = await server.remove_sell_online(payload);
      if (code === 0) {
        message.success('移除成功');
        this.refresh();
        cb && cb();
      }
      this.setState({
        buttonLoading: false,
      });
    },
    // 课程下架
    async off_shelf(payload, rootStates, cb) {
      this.setState({
        buttonLoading: true,
      });
      const { code } = await server.off_shelf(payload);
      if (code === 0) {
        message.success('下架成功');
        this.refresh();
        cb && cb();
      }
      this.setState({
        buttonLoading: false,
      });
    },
    // 课程上架
    async on_shelf(payload, rootStates, cb) {
      this.setState({
        buttonLoading: true,
      });
      const { code } = await server.on_shelf(payload);
      if (code === 0) {
        message.success('上架成功');
        this.refresh();
        cb && cb();
      }
      this.setState({
        buttonLoading: false,
      });
    },
    // 课程详情-预览用
    async get_course_detail_for_preview(payload, rootStates, cb) {
      this.setState({
        detailLoading: true,
      });
      const { code, data } = await server.get_course_detail_for_preview(payload);
      if (code === 0) {
        cb && cb(data);
      }
      this.setState({
        detailLoading: false,
      });
    },
    // 课程详情-编辑用
    async get_course_detail_by_id(payload, rootStates, cb) {
      this.setState({
        detailLoading: true,
      });
      const { code, data } = await server.get_course_detail_by_id(payload);
      if (code === 0) {
        cb && cb(data);
      }
      this.setState({
        detailLoading: false,
      });
    },
    // 完善线上售卖信息
    async save_online_course_info(payload, rootStates, cb) {
      this.setState({
        createButtonLoading: true,
      });
      const { code, data } = await server.save_online_course_info(payload);
      if (code === 0) {
        message.success('保存成功');
        cb && cb(data);
      }
      this.setState({
        createButtonLoading: false,
      });
    },
    // 全量线上售卖课程列表
    async list_all_online_course_info(payload, rootStates, cb) {
      const { code, data } = await server.list_all_online_course_info(payload);
      if (code === 0) {
        this.setState({
          courseList: data || [],
        });
        cb && cb(data);
      }
      this.setState({
        detailLoading: false,
      });
    },
    // 添加线上展示的机构教师
    async add_org_teacher(payload, rootStates, cb) {
      this.setState({
        buttonLoading: true,
      });
      const { code } = await server.add_org_teacher(payload);
      if (code === 0) {
        message.success('添加成功');
        cb && cb();
      }
      this.setState({
        buttonLoading: false,
      });
    },
    // 移除线上展示的机构教师
    async remove_org_teacher(payload, rootStates, cb) {
      this.setState({
        buttonLoading: true,
      });
      const { code } = await server.remove_org_teacher(payload);
      if (code === 0) {
        message.success('移除成功');
        cb && cb();
      }
      this.setState({
        buttonLoading: false,
      });
    },
    // 刷新页面
    refresh() {
      this.setState({
        refreshTime: new Date().getTime(),
      });
    },
  }),
};
