import React from 'react';
import LoginLabel from '@/assets/img/goodclasslogo.svg';
import UserInfo from './UserInfoArea';
import styles from './index.module.less';

const Header = () => {
  return (
    <div className={styles.dashboardHeader}>
      <div className={styles.dashboardIconContainer}>
        <div className={styles.dashboardIcon}>
          <img src={LoginLabel} alt="logo" />
        </div>
      </div>
      <div className={styles.dashboardInfo}>
        <UserInfo />
      </div>
    </div>
  );
};

export default Header;
