import { message } from 'antd';
import * as server from './server';

export default {
  state: {
    loading: false,
    list: [],
    parentDetail: {},

    createButtonLoading: false,
    deleteButtonLoading: false,
    detailLoading: false,

    modalLoading: false,

    refreshTime: new Date().getTime(),

    EDUCATION_CATEGORY: [],
  },
  reducers: {
    setState(state, payload) {
      Object.keys(payload).forEach((item) => {
        state[item] = payload[item];
      });
    },
  },
  effects: () => ({
    // 字典项调用
    async getDict(payload, rootStates, cb) {
      const { code, data } = await server.getTypeSelect(payload)
      if (code === 0) {
        this.setState({
          [payload.code]: data,
        })
      }
      cb && cb();
    },
    // 创建修改教室
    async createParent(payload, rootStates, cb) {
      this.setState({
        createButtonLoading: true,
      });
      if (payload.id) {
        const { code } = await server.updateParent(payload);
        if (code === 0) {
          message.success('修改成功');
          cb && cb();
        }
        this.setState({
          createButtonLoading: false,
        });
      } else {
        const { code } = await server.createParent(payload);
        if (code === 0) {
          message.success('创建成功');
          cb && cb();
        }
        this.setState({
          createButtonLoading: false,
        });
      }
    },
    // 检查家长是否可以删除
    async checkParentDelete(payload, rootStates, cb) {
      this.setState({
        modalLoading: true,
      });
      const { code, data } = await server.checkParentDelete(payload);
      if (code === 0 && data) {
        cb && cb(data);
      }
      this.setState({
        modalLoading: false,
      });
    },
    // 删除家长
    async deleteParent(payload, rootStates, cb) {
      this.setState({
        deleteButtonLoading: true,
      });
      const { code } = await server.deleteParent(payload);
      if (code === 0) {
        message.success('删除成功');
        cb && cb();
      }
      this.setState({
        deleteButtonLoading: false,
      });
    },
    // 获取家长详情
    async getParentInfo(payload, rootStates, cb) {
      this.setState({
        detailLoading: true,
      });
      const { code, data } = await server.getParentInfo(payload);
      if (code === 0) {
        this.setState({
          parentDetail: data,
        });
        cb && cb(data);
      }
      this.setState({
        detailLoading: false,
      })
    },

    // 刷新页面
    refresh() {
      this.setState({
        refreshTime: new Date().getTime(),
      })
    }
  }),
};
