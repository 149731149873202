// import moment from 'moment';

function produceOption(data) {
  const option = {
    tooltip: {
      trigger: 'axis',
      // position: ['80%', '0%']
    },
    legend: {
      type: 'scroll',
      orient: 'vertical',
      top: 'middle',
      // bottom: 0,
      right: 0,
      align: 'right',
      pageIconSize: 10,
      // data: data.seriesList.map(item=>item.subjectName)
    },
    grid: {
      top: '11%',
      left: '3%',
      right: '20%',
      bottom: '3%',
      containLabel: true,
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: data.xaxisData
    },
    yAxis: {
      type: 'value',
      axisLine: {
        show: false
      },
      axisTick: {
        show: false
      }
    },
    series: data.seriesList.map(item=>{
      return {
        name: item.subjectName,
        type: 'line',
        // stack: 'total',
        label:{
          show:true,
          // position:'insideTop',
          color:'#333',
          textBorderWidth: 0,
          formatter: (params) => {
            if (!params.value) return ''
            return params.value
          }
        },
        data: item.dataList
      }
    })
  };

  return option;
}

export default produceOption;
