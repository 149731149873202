import React, { useState } from 'react';
import {
  Icon,
  Badge,
  Dropdown,
  Menu,
} from 'antd';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import TriangleLabel from '@/components/Common/TriangleLabel';
import UserUpload from '@/assets/img/userUpload.png';
import OrganQrCode from '@/assets/img/organQrCode.png';
import openNewPage from '@/utils/openNewPage';
import Modal from '@/components/Common/modalTouchSelf';
import { getOrganQrCode } from './server';
import styles from './index.module.less';

const UserInfo = (props) => {
  const {
    history,
    tipWordStyle,
    AccountSet: {
      userInfo,
    },
  } = props;

  const [organQrCodeImg, setOrganQrCodeImg] = useState('');

  // 登出
  const logout = () => {
    window.localStorage.clear();
    openNewPage('/login', '_self');
  };

  // 跳转个人账户
  const jumpAccount = () => {
    history.push('/dashboard/set');
    window.localStorage.setItem('recordOrigization', false);
  };

  const switchShop = () => {
    history.push('/dashboard/shop');
  };

  // tipWord 样式
  const tipWordUI = {
    marginLeft: '17px',
    ...tipWordStyle,
  };

  const menu = (
    <Menu>
      <Menu.Item>
        <div className={styles.userinfoname}> {userInfo.userName}</div>
      </Menu.Item>
      <Menu.Item>
        <div onClick={switchShop}>
          <Icon type="swap" />切换机构
        </div>
      </Menu.Item>
      <Menu.Item>
        <div onClick={jumpAccount}>
          <Icon type="setting" />个人账号
        </div>
      </Menu.Item>
      <Menu.Item>
        <div onClick={logout}>
          <Icon type="poweroff" />退出登录
        </div>
      </Menu.Item>
    </Menu>
  );

  // 获取二维码
  const getQrCode = () => {
    getOrganQrCode().then((res) => {
      if (res.code === 0) {
        // console.log('res', res);
        setOrganQrCodeImg(res.data);
      }
    });
  };

  // 渲染二维码
  const renderQrCode = (
    <div className={styles.qrCodeImgFrame}>
      <img src={organQrCodeImg} alt="机构二维码" />
    </div>
  );

  return (
    <div className={styles.UserInfoContainer}>
      {history.location.pathname.indexOf('/dashboard/shop') === -1 ? (
        <div className={styles.tipWord} style={tipWordUI}>
          当前机构: {window.localStorage.getItem('orgName') || ''}
          &nbsp;&nbsp;
          {/* <Modal
            title="机构二维码"
            labelShow
            width="414px"
            renderComponent={renderQrCode}
            openModalCallback={getQrCode}
          >
            <img src={OrganQrCode} alt="机构二维码" />
          </Modal> */}
        </div>
      ) : <div className={styles.tipWord} />}
      <div className={styles.infoPart}>
        {/* <Badge count={20}> */}
        <a href="https://eyxt6j.helplookapp.com/docs/ping-tai-gai-lan" rel="noopener noreferrer" target="_blank" style={{ marginRight: '20px', color: 'rgba(0, 0, 0, 0.85)' }}>帮助中心</a>
        <Badge count={0}>
          <Icon
            type="bell"
            theme="filled"
            style={{ color: '#D6D5E5' }}
          />
        </Badge>
        {/* <Icon type="ellipsis" className={styles.headerInfoTip} /> */}
        <div className={styles.downloadTochContainer}>
          <Dropdown
            overlay={menu}
            getPopupContainer={trigger => trigger.parentNode}
          >
            <div className={styles.downloadTochFrame}>
              <div className={styles.imgPerson}>
                <img src={userInfo.avatarUrl || UserUpload} alt="头像" />
              </div>
              <div className={styles.TriangleLabelContainer}>
                <TriangleLabel />
              </div>
            </div>
          </Dropdown>
        </div>

      </div>
    </div>
  );
};

UserInfo.defaultProps = {
  tipWordStyle: {},
};
UserInfo.propTypes = {
  tipWordStyle: PropTypes.object,
};

export default withRouter(connect(({ AccountSet }) => ({
  AccountSet,
}))(UserInfo));
