import React from 'react';
import {
  Modal, Form, Button,
} from 'antd';
import PropTypes from 'prop-types';
import styles from '../index.module.less';
import closeImg from '@/assets/img/closeModal.png';
import successImg from '@/assets/img/successImg.png';

const FormInput = (props) => {
  const {
    footer,
    width,
    visible,
    closeModal,
    getContainer,
    centered,
  } = props;

  // 关闭弹窗
  const handleCancel = () => {
    closeModal(false);
  };

  return (
    <Modal
      className={styles.successWrap}
      maskClosable={false}
      getContainer={getContainer}
      visible={visible}
      width={width}
      onCancel={handleCancel}
      footer={footer || null}
      centered={centered}
    >
      <div className={styles.formContainer}>
        <img className={styles.successImg} src={successImg} alt="" />
        <img onClick={() => { handleCancel(); }} className={styles.closeImg} src={closeImg} alt="" />
        <div className={styles.title}>提交成功</div>
        <div className={styles.subtitle}>工作人员会尽快跟您联系！</div>
        <Button className={styles.btn} type="default" onClick={handleCancel}>关闭</Button>
      </div>
    </Modal>
  );
};

const FormInputForm = Form.create({ name: 'normal_login' })(FormInput);


FormInput.defaultProps = {
  footer: null,
  width: 520,
  visible: false,
  closeModal: () => {},
  getContainer: false,
  centered: true,
};
FormInput.propTypes = {
  footer: PropTypes.element,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  visible: PropTypes.bool,
  closeModal: PropTypes.func,
  getContainer: PropTypes.oneOfType([PropTypes.element, PropTypes.bool]),
  centered: PropTypes.bool,
};

export default FormInputForm;
