import { message } from 'antd';
import * as server from './server';

export default {
  state: {
    loading: false,
    detailInfo: {},
    allAccount: [], // 帐号列表(采单员)
    allLeadsSource: [], // 线索来源
    createButtonLoading: false,
    detailLoading: false,
    buttonLoading: false,
    refreshTime: new Date().getTime(),
  },
  reducers: {
    setState(state, payload) {
      Object.keys(payload).forEach((item) => {
        state[item] = payload[item];
      });
    },
  },
  effects: () => ({
    // 获取已绑定帐号列表(采单员)
    async get_account_relation_list(payload, rootStates, cb) {
      const { code, data } = await server.get_account_relation_list(payload);
      if (code === 0) {
        this.setState({
          allAccount: data || [],
        });
        cb && cb();
      }
    },
    // 获取线索来源
    async get_leads_source_code(payload, rootStates, cb) {
      const { code, data } = await server.get_leads_source_code(payload);
      if (code === 0) {
        this.setState({
          allLeadsSource: data || [],
        });
        cb && cb();
      }
    },
    // 创建修改采单码
    async create_info_acquisition_code(payload, rootStates, cb) {
      this.setState({
        createButtonLoading: true,
      });
      if (payload.id) {
        const { code } = await server.update_info_acquisition_code(payload);
        if (code === 0) {
          message.success('修改成功');
          cb && cb();
        }
        this.setState({
          createButtonLoading: false,
        });
      } else {
        const { code } = await server.create_info_acquisition_code(payload);
        if (code === 0) {
          message.success('创建成功');
          cb && cb();
        }
        this.setState({
          createButtonLoading: false,
        });
      }
    },
    // 作废
    async invalid_info_acquisition_code(payload, rootStates, cb) {
      this.setState({
        buttonLoading: true,
      });
      const { code } = await server.invalid_info_acquisition_code(payload);
      if (code === 0) {
        message.success('作废成功');
        cb && cb();
      }
      this.setState({
        buttonLoading: false,
      });
    },
    // 恢复
    async renew_info_acquisition_code(payload, rootStates, cb) {
      this.setState({
        buttonLoading: true,
      });
      const { code } = await server.renew_info_acquisition_code(payload);
      if (code === 0) {
        message.success('恢复成功');
        cb && cb();
      }
      this.setState({
        buttonLoading: false,
      });
    },
    // 刷新采单员用户名称
    async refresh_account_name(payload, rootStates, cb) {
      this.setState({
        buttonLoading: true,
      });
      const { code, data } = await server.refresh_account_name(payload);
      if (code === 0) {
        cb && cb(data);
      }
      this.setState({
        buttonLoading: false,
      });
    },
    // 采单码详情
    async info_acquisition_code_detail(payload, rootStates, cb) {
      this.setState({
        detailLoading: true,
      });
      const { code, data } = await server.info_acquisition_code_detail(payload);
      if (code === 0) {
        this.setState({
          detailInfo: data,
        });
        cb && cb(data);
      }
      this.setState({
        detailLoading: false,
      });
    },
    // 获取个人信息
    async getUserInfo(payload, rootStates, cb) {
      const { code, data } = await server.getUserInfo(payload);
      if (code === 0) {
        cb && cb(data);
      }
    },
    // 刷新页面
    refresh() {
      this.setState({
        refreshTime: new Date().getTime(),
      });
    },
  }),
};
