function produceOption(data) {
    const option = {
      tooltip: {
        trigger: 'axis',
        axisPointer:{
          type:'shadow'
        },
        // position: ['68%', '-100%']
      },
      legend: {
        type: 'scroll',
        orient: 'vertical',
        top: 'middle',
        // bottom: 0,
        right: 0,
        align: 'right',
        pageIconSize: 10,
        itemWidth: 14,
        data: data.seriesList.map(item=>item.legendName)
      },
      grid: {
        top: '10%',
        left: '3%',
        right: '25%',
        bottom: '3%',
        containLabel: true,
      },
      xAxis: {
        type: 'category',
        axisTick: {
          interval: 0
        },
        // boundaryGap: false,
        data: data.xaxisData
      },
      yAxis: {
        type: 'value',
        axisLine: {
          show: false
        },
        axisTick: {
          show: false
        }
      },
      series: data.seriesList.map(item => {
        return {
            name: item.legendName,
            type: 'bar',
            stack: item.legendName.substr(-2)==='添加'?'ADD':'DEDUCT',
            label:{
              show:true,
              fontSize: 10,
              position:'insideTop',
              color:'#fff',
              textBorderWidth: 0,
              formatter: (params) => {
                if (!params.value) return ''
                return params.value
              }
            },
            emphasis: {
              focus: 'series'
            },
            data: item.dataList
          }
      })
    };
  
    return option;
  }
  
  export default produceOption;
  