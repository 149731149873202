import request from '@/service';

// 新建家长
export async function createParent(params) {
  return request('/person-service/parent_info/create_parent', {
    method: 'post',
    data: params,
  });
}

// 获取家长信息
export async function getParentInfo(params) {
  return request('/person-service/parent_info/parent_info_by_id', {
    method: 'get',
    data: params,
  });
}

// 修改家长信息
export async function updateParent(params) {
  return request('/person-service/parent_info/update_parent', {
    method: 'post',
    data: params,
  });
}

// 检查家长是否可以删除
export async function checkParentDelete(params) {
  return request('/person-service/parent_info/valid_delete_parent_info', {
    method: 'get',
    data: params,
  });
}

// 删除家长
export async function deleteParent(params) {
  return request('/person-service/parent_info/delete_parent_info', {
    method: 'delete',
    data: params,
  });
}

// 获取下拉类型字典项
export async function getTypeSelect(params) {
  return request('/organization-service/dict/get_drop_down_list', {
    method: 'get',
    data: params,
  });
}