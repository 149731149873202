import { message } from 'antd';
import * as server from './server';

export default {
  state: {
    loading: false,
    list: [],
    coursewareDetail: {},
    coursewareDeleteInfo: {},
    createButtonLoading: false,
    deleteButtonLoading: false,
    detailLoading: false,
    refreshTime: new Date().getTime(),
  },
  reducers: {
    setState(state, payload) {
      Object.keys(payload).forEach((item) => {
        state[item] = payload[item];
      });
    },
  },
  effects: () => ({
    // 创建修改课件
    async createCourseware(payload, rootStates, cb) {
      this.setState({
        createButtonLoading: true,
      });
      if (payload.id) {
        const { code } = await server.updateCourseware(payload);
        if (code === 0) {
          message.success('修改成功');
          cb && cb();
        }
        this.setState({
          createButtonLoading: false,
        });
      } else {
        const { code } = await server.createCourseware(payload);
        if (code === 0) {
          message.success('创建成功');
          cb && cb();
        }
        this.setState({
          createButtonLoading: false,
        });
      }
    },
    // 删除教室
    async deleteCourseware(payload, rootStates, cb) {
      this.setState({
        deleteButtonLoading: true,
      });
      const { code } = await server.deleteCourseware(payload);
      if (code === 0) {
        message.success('删除成功');
        cb && cb();
      }
      this.setState({
        deleteButtonLoading: false,
      });
    },
    // // 获取教室详情
    // async getClassRoomInfo(payload, rootStates, cb) {
    //   this.setState({
    //     detailLoading: true,
    //   });
    //   const { code, data } = await server.getClassRoomInfo(payload);
    //   if (code === 0) {
    //     this.setState({
    //       classRoomDetail: data,
    //     });
    //     cb && cb(data);
    //   }
    //   this.setState({
    //     detailLoading: false,
    //   })
    // },

    // 刷新页面
    refresh() {
      this.setState({
        refreshTime: new Date().getTime(),
      });
    },
  }),
};
