import request from '@/service';

// 新建视频
export async function createVideo(params) {
  return request('/curriculum-service/record_video_info/create', {
    method: 'post',
    data: params,
  });
}

// 修改视频信息
export async function updateVideo(params) {
  return request('/curriculum-service/record_video_info/update', {
    method: 'post',
    data: params,
  });
}

// 删除视频
export async function deleteVideo(params) {
  return request('/curriculum-service/record_video_info/delete', {
    method: 'post',
    data: params,
  });
}

// 获取播放地址
export async function getPreviewUrl(params) {
  return request('/curriculum-service/record_video_info/get_play_url', {
    method: 'get',
    data: params,
  });
}
