import QRCode from 'qrcode';
import * as server from './server';

export default {
  state: {
    loading: false,
    userInfo: {},

    qrCodeLoading: false,
    qrCodeUrl: null,

    menu: [],
    refreshMenu: new Date().getTime(),
    buttonLoading: false,
  },
  reducers: {
    setState(state, payload) {
      Object.keys(payload).forEach((item) => {
        state[item] = payload[item];
      });
    },
    mergeUserInfo(state, payload) {
      state.userInfo = {
        ...state.userInfo,
        ...payload,
      };
    },
  },
  effects: () => ({
    // 获取用户个人信息
    async getUserInfo(cb) {
      this.setState({
        loading: true,
      });
      const { code, data } = await server.getUserInfo();
      if (code === 0) {
        this.setState({
          userInfo: data,
        });
        // console.log('data', data)
        if (data.userId) {
          // 存储 userId
          window.localStorage.setItem('userId', data.userId);
          window.localStorage.setItem('recordOrigization', false);
        }
      }
      this.setState({
        loading: false,
      })
    },

    // 获取二维码
    async getQrCode() {
      this.setState({
        qrCodeLoading: true,
      });
      const res = await server.getQrCode();
      if (res.code === 0) {
        QRCode.toDataURL(res.data)
          .then(url => {
            this.setState({
              qrCodeUrl: url,
            })
            console.log(url)
          })
          .catch(err => {
            console.error(err)
          })
      } else {
        this.setState({
          qrCodeUrl: null,
        })
      }
      this.setState({
        qrCodeLoading: false,
        loading: false,
      });
    },
    // 修改昵称
    async putUserInfo(payload, rootStates, cb) {
      let res = {};
      if (payload.type === 'userName') {
        res = await server.putNickname(payload.applyItems);
      }
      if (payload.type === 'tips') {
        res = await server.putSign(payload.applyItems);
      }
      if (payload.type === 'email') {
        res = await server.putMail(payload.applyItems);
      }
      if (payload.type === 'password') {
        res = await server.putPassword(payload.applyItems);
      }
      if (payload.type === 'phone') {
        res = await server.putPhone(payload.applyItems);
      }
      // console.log('ddd', res.code)
      if (res.code === 0) {
        // 存在浏览器中
        let browserUserInfo = {};
        browserUserInfo = JSON.parse(window.localStorage.getItem('userInfo'));
        browserUserInfo[payload.type] = payload.applyItems[payload.type];
        // console.log('browserUserInfo', browserUserInfo)
        window.localStorage.setItem('userInfo', JSON.stringify(browserUserInfo));
        // 存在 store 中
        this.mergeUserInfo({
          [payload.type]: payload.applyItems[payload.type],
        });
        cb && cb();
      }
    },
    // 发送重置密码验证码
    async sendResetPasswordCheckCode(payload, rootStates, cb) {
      const res = await server.sendRestPassowrdCode(payload);
      if (res.code === 0) {
        cb && cb();
      }
    },
    // 发送重置手机号验证码
    async sendResetPhoneCheckCode(payload, rootStates, cb) {
      const res = await server.sendRestPhoneCode(payload);
      if (res.code === 0) {
        cb && cb();
      }
    },
    // 发送重置邮箱验证码
    async sendResetMailCheckCode(payload, rootStates, cb) {
      const res = await server.sendRestMailCode(payload);
      if (res.code === 0) {
        cb && cb();
      }
    },
    // 取消微信绑定
    async unbindWx(payload, rootStates, cb) {
      // this.setState({
      //   buttonLoading: true
      // })
      // console.log(111)
      const res = await server.unbindWx(payload);
      // this.setState({
      //   buttonLoading: false
      // })
      if (res.code === 0) {
        cb && cb();
      }
    },

    // 菜单权限
    async getMenu(payload, rootStates, cb) {
      const res = await server.getMenu(payload);
      if (res.code === 0) {
        this.setState({
          menu: res.data
        })
        cb && cb(res.data);
      } 
    },

    // 刷新菜单
    refreshMenu() {
      this.setState({
        refreshMenu: new Date().getTime()
      })
    },

    // 清除缓存
    async clearCache() {
      await server.clearCache();
    }
  }),
};
