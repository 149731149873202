import request from '@/service';

// 获取下拉信息
export async function getSelect(params) {
  return request('/organization-service/dict/get_drop_down_list', {
    method: 'get',
    data: params,
  });
}

// 创建课程
export async function createCourse(params) {
  return request('/curriculum-service/course_info/create_course_info_v2', {
    method: 'post',
    data: params,
  });
}

// 修改
export async function updateCourse(params) {
  return request('/curriculum-service/course_info/update_course_info_v2', {
    method: 'post',
    data: params,
  });
}

// 获取课程简要信息
export async function getCourseInfo(params) {
  return request('/curriculum-service/course_info/get_course_simple_info_by_id', {
    method: 'get',
    data: params,
  });
}

// 检查是否删除课程
export async function checkDeleteCourse(params) {
  return request('/curriculum-service/course_info/check_delete_course_info', {
    method: 'get',
    data: params,
  });
}

// 删除教室信息
export async function deleteCourse(params) {
  return request('/curriculum-service/course_info/delete_course_info', {
    method: 'delete',
    data: params,
  });
}

// 获取课程大纲列表(不分页)
export async function getOutlineList(params) {
  return request('/curriculum-service/syllabus_info/list_little_syllabus_simple_info_items', {
    method: 'get',
    data: params,
  });
}

// 上架或者下架
export async function upOrdownShelf(params) {
  if (params.status === 'NOT_ON_SHELF') {
    return request('/curriculum-service/course_info/off_shelf', {
      method: 'post',
      data: {
        courseInfoId: params.courseInfoId,
      },
    });
  }
  if (params.status === 'ON_SHELF') {
    return request('/curriculum-service/course_info/on_shelf', {
      method: 'post',
      data: {
        courseInfoId: params.courseInfoId,
      },
    });
  }
  // return request('/curriculum-service/course_info/change_course_info_status', {
  //   method: 'post',
  //   data: params,
  // });
}

// 获取课程详情
export async function getClassDetail(params) {
  return request('/curriculum-service/course_info/get_course_detail_info_by_id', {
    method: 'get',
    data: params,
  });
}

// 获取课程大纲实例
export async function getClassOutline(params) {
  return request('/curriculum-service/syllabus_instance/get_syllabus_instance_by_course_id', {
    method: 'get',
    data: params,
  });
}

// 设置大纲实例授课时间
export async function setOutlineTime(params) {
  return request('/curriculum-service/syllabus_instance/set_teach_time', {
    method: 'post',
    data: params,
  });
}

// 获取课时类型
export async function getClassHourTypeList(params) {
  return request('/class-hour-service/class_hour_type_code/list_all', {
    method: 'get',
    data: params,
  });
}

// 清空大纲实例授课时间
export async function clearTeachTime(params) {
  return request('/curriculum-service/syllabus_instance/clear_teach_time', {
    method: 'post',
    data: params,
  });
}

// 绑定课件
export async function bindCourseware(params) {
  return request('/curriculum-service/syllabus_instance/bind_document', {
    method: 'post',
    data: params,
  });
}

// 解绑课件
export async function unbindCourseware(params) {
  return request('/curriculum-service/syllabus_instance/unbind_document', {
    method: 'post',
    data: params,
  });
}

// 绑定视频
export async function bindVideo(params) {
  return request('/curriculum-service/syllabus_instance/bind_record_video', {
    method: 'post',
    data: params,
  });
}

// 解绑视频
export async function unbindVideo(params) {
  return request('/curriculum-service/syllabus_instance/unbind_record_video', {
    method: 'post',
    data: params,
  });
}

// 课程大纲实例节点列表
export async function get_syllabus_instance(params) {
  return request('/curriculum-service/syllabus_instance/get_syllabus_instance_for_sync', {
    method: 'get',
    data: params,
  });
}

// 课程大纲实例节点列表
export async function get_syllabus_content(params) {
  return request('/curriculum-service/syllabus_instance/get_syllabus_content_for_sync', {
    method: 'get',
    data: params,
  });
}
// 删除课程大纲实例节点
export async function delete_syllabus_instance_content(params) {
  return request('/curriculum-service/syllabus_instance/delete_syllabus_instance_content', {
    method: 'post',
    data: params,
  });
}
// 同步课程大纲节点
export async function sync_syllabus_content(params) {
  return request('/curriculum-service/syllabus_instance/sync_syllabus_content', {
    method: 'post',
    data: params,
  });
}

// 全量课程大纲列表
export async function getAllSyllabus(params) {
  return request('/curriculum-service/syllabus_info/list_all_syllabus_info', {
    method: 'get',
    data: params,
  });
}

// 全量课程列表
export async function getAllCourse(params) {
  return request('/curriculum-service/course_info/list_all_course_info', {
    method: 'get',
    data: params,
  });
}

// 全量课程列表(订单使用)
export async function getAllCourseForOrder(params) {
  return request('/curriculum-service/course_info/list_all_course_for_create_order', {
    method: 'get',
    data: params,
  });
}
