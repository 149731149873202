import request from '@/service';

// 获取各种下拉信息
export async function getSelect(params) {
  return request('/organization-service/dict/get_drop_down_list', {
    method: 'get',
    data: params,
  });
}

// 新建大纲
export async function createOutline(params) {
  return request('/curriculum-service/syllabus_info/create_syllabus_info', {
    method: 'post',
    data: params,
  });
}

// 修改大纲主体信息
export async function updateOutline(params) {
  return request('/curriculum-service/syllabus_info/update_syllabus_abstract_info', {
    method: 'post',
    data: params,
  });
}

// 获取大纲详情
export async function getDetail(params) {
  return request('/curriculum-service/syllabus_info/syllabus_info_detail', {
    method: 'get',
    data: params,
  });
}

// 删除课程大纲
export async function deleteOutline(params) {
  return request('/curriculum-service/syllabus_info/delete_syllabus_info', {
    method: 'delete',
    data: params,
  });
}

// 创建课程大纲节点
export async function createNode(params) {
  return request('/curriculum-service/syllabus_content/create_syllabus_content', {
    method: 'post',
    data: params,
  });
}

// 修改课程大纲父节点
export async function updateParentNode(params) {
  return request('/curriculum-service/syllabus_content/update_syllabus_parent_content', {
    method: 'post',
    data: params,
  });
}

// 修改课程大纲课节节点
export async function updateChildNode(params) {
  return request('/curriculum-service/syllabus_content/update_syllabus_child_content', {
    method: 'post',
    data: params,
  });
}

// 删除课程大纲父节点
export async function deleteParentNode(params) {
  return request('/curriculum-service/syllabus_content/delete_syllabus_parent_content', {
    method: 'delete',
    data: params,
  });
}

// 删除课程大纲课节节点
export async function deleteChildNode(params) {
  return request('/curriculum-service/syllabus_content/delete_syllabus_child_content', {
    method: 'delete',
    data: params,
  });
}

// 课节绑定课件
export async function bindCourseware(params) {
  return request('/curriculum-service/syllabus_content/bind_document_syllabus_child_content', {
    method: 'post',
    data: params,
  });
}

// 课节解绑课件
export async function unbindCourseware(params) {
  return request('/curriculum-service/syllabus_content/unbound_document_syllabus_child_content', {
    method: 'post',
    data: params,
  });
}

// 课节绑定视频
export async function bindVideo(params) {
  return request('/curriculum-service/syllabus_content/bind_record_video', {
    method: 'post',
    data: params,
  });
}

// 课节解绑视频
export async function unbindVideo(params) {
  return request('/curriculum-service/syllabus_content/unbind_record_video', {
    method: 'post',
    data: params,
  });
}

// 批量操作
export async function patchUpdate(params) {
  return request('/curriculum-service/syllabus_content/batch_update_syllabus_child_content', {
    method: 'post',
    data: params,
  });
}

// 获取大纲详情列表
export async function getOutlineList(params) {
  return request('/curriculum-service/syllabus_info/content_list', {
    method: 'get',
    data: params,
  });
}

// 获取课时类型
export async function getClassHourTypeList(params) {
  return request('/class-hour-service/class_hour_type_code/list_all', {
    method: 'get',
    data: params,
  });
}

// 获取课节详情
export async function getClassNodeDetail(params) {
  return request('/curriculum-service/syllabus_content/get_syllabus_content', {
    method: 'get',
    data: params,
  });
}

// 新建大纲
export async function copyOutline(params) {
  return request('/curriculum-service/syllabus_info/copy_syllabus_info', {
    method: 'post',
    data: params,
  });
}