import React from 'react';
import FormInput from './FormInput';
import styles from '../index.module.less';

const Register = () => {
  return (
    <>
      <div className={styles.selectLoginStyleFrame}>
        <div className={styles.selectedStatus}>
          注册
        </div>
      </div>
      {/* 输入框 */}
      <div className={styles.formInputFrame}>
        <FormInput />
      </div>
    </>
  );
};

export default Register;
