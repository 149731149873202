function produceOption(data) {
  const option = {
    tooltip: {
      trigger: 'axis',
      axisPointer:{
        type:'shadow'
      }
    },
    legend: {
      orient: 'vertical',
      top: 'middle',
      right: 0,
      align: 'right',
      itemWidth: 14,
      data: data.seriesList.map(item=>item.legendName)
    },
    grid: {
      top: '10%',
      left: '3%',
      right: '14%',
      bottom: '3%',
      containLabel: true,
    },
    xAxis: {
      type: 'category',
      axisTick: {
        interval: 0
      },
      // boundaryGap: false,
      data: data.xaxisData
    },
    yAxis: {
      type: 'value',
      axisLine: {
        show: false
      },
      axisTick: {
        show: false
      }
    },
    series: [
      {
        name: data.seriesList[0].legendName,
        type: 'bar',
        stack: 'total',
        label:{
          show:true,
          position:'insideTop',
          color:'#fff',
          textBorderWidth: 0,
          formatter: (params) => {
            if (!params.value) return ''
            return params.value
          }
        },
        emphasis: {
          focus: 'series'
        },
        itemStyle: {
          color: '#ccc'
        },
        barCategoryGap: '30%',
        data: data.seriesList[0].dataList
      },
      {
        name: data.seriesList[1].legendName,
        type: 'bar',
        stack: 'total',
        label:{
          show:true,
          fontSize: 10,
          position:'insideTop',
          color:'#fff',
          textBorderWidth: 0,
          formatter: (params) => {
            if (!params.value) return ''
            return params.value
          }
        },
        emphasis: {
          focus: 'series'
        },
        itemStyle: {
          color: '#5470c6'
        },
        barCategoryGap: '30%',
        data: data.seriesList[1].dataList
      }
    ],
  };

  return option;
}

export default produceOption;
