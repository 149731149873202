import { message } from 'antd';
import * as server from './server';

export default {
  state: {
    loading: false,
    list: [],
    classRoomDetail: {},

    createButtonLoading: false,
    deleteButtonLoading: false,
    detailLoading: false,

    refreshTime: new Date().getTime(),
  },
  reducers: {
    setState(state, payload) {
      Object.keys(payload).forEach((item) => {
        state[item] = payload[item];
      });
    },
  },
  effects: () => ({
    // 创建修改班级
    async createClass(payload, rootStates, cb) {
      this.setState({
        createButtonLoading: true,
      });
      if (payload.teamId) {
        const { code } = await server.updateClass(payload);
        if (code === 0) {
          message.success('修改成功');
          cb && cb();
        }
        this.setState({
          createButtonLoading: false,
        });
      } else {
        const { code } = await server.createClass(payload);
        if (code === 0) {
          message.success('创建成功');
          cb && cb();
        }
        this.setState({
          createButtonLoading: false,
        });
      }
    },
    // 删除班级
    async deleteClass(payload, rootStates, cb) {
      this.setState({
        deleteButtonLoading: true,
      });
      const { code } = await server.deleteClass(payload);
      if (code === 0) {
        message.success('删除成功');
        cb && cb();
      }
      this.setState({
        deleteButtonLoading: false,
      });
    },

    // 班级添加学生
    async addStudent(payload, rootStates, cb) {
      const { code } = await server.addStudent(payload);
      if (code === 0) {
        message.success('添加成功');
        cb && cb();
      }
    },

    // 班级删除学生
    async deleteStudent(payload, rootStates, cb) {
      const { code } = await server.deleteStudent(payload);
      if (code === 0) {
        message.success('删除成功');
        cb && cb();
      }
    },

    // 刷新页面
    refresh() {
      this.setState({
        refreshTime: new Date().getTime(),
      })
    }
  }),
};
