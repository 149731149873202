import React, { useState, useEffect } from 'react';
import {
  Modal, Form, Button, Input, message,
} from 'antd';
import PropTypes from 'prop-types';
import styles from '../index.module.less';
import closeImg from '@/assets/img/closeModal.png';
import {
  phoneReg,
} from '@/utils/reg';
import { freeTrial, sendFreeTrialCode } from '../server.js';

const FormItem = Form.Item;

const FormInput = (props) => {
  const [btnText, setBtnText] = useState('获取验证码');
  const [sendCodeFlag, setSendCodeFlag] = useState(false);

  let timer = null;

  const {
    title,
    footer,
    width,
    visible,
    closeModal,
    submitSuccess,
    labelShow,
    hasOverFlowScroll,
    getContainer,
    centered,
    paddingZero,
    form: {
      getFieldDecorator,
      getFieldsValue,
      validateFields,
      setFieldsValue,
    },
    phone,
  } = props;

  useEffect(() => {
    if (phone) {
      setFieldsValue({ phone });
    }
  }, [phone]);

  // 关闭弹窗
  const handleCancel = () => {
    closeModal(false);
  };

  // 头部标题
  const TitleComponent = data => (
    <>
      <div className={styles.modalTitle}>{data}</div>
      <img onClick={() => { handleCancel(); }} className={styles.closeImg} src={closeImg} alt="" />
    </>
  );

  const getCode = () => {
    if (sendCodeFlag) return;
    setSendCodeFlag(true);
    if (!phoneReg.test(getFieldsValue().phone) || !getFieldsValue().phone) {
      message.warning('请填写正确的手机号!');
      return;
    }
    sendFreeTrialCode({
      phone: getFieldsValue().phone,
    }).then((res) => {
      if (res.code === 0) {
        let time = 60;

        timer = setInterval(() => {
          time -= 1;
          if (time < 1) {
            setBtnText('重新发送');
            clearInterval(timer);
            setSendCodeFlag(false);
          } else {
            setBtnText(`${time}s`);
          }
        }, 1000);
      }
    });
  };

  const submit = () => {
    validateFields((err, values) => {
      if (!values.orgName) {
        message.warning('请输入机构名称!');
        return;
      }
      if (!phoneReg.test(values.phone) || !values.phone) {
        message.warning('请填写正确的手机号!');
        return;
      }
      if (!values.code) {
        message.warning('请输入验证码!');
        return;
      }
      if (!err) {
        freeTrial({
          ...values,
          sourceChannel: 'PC_ADMIN',
        }).then((res) => {
          if (res && res.code === 0) {
            submitSuccess();
          }
        });
      }
    });
  };

  return (
    <Modal
      className={`${paddingZero ? styles.paddingZero : styles.modalContainer} ${
        hasOverFlowScroll ? styles.hasOverFlowScroll : ''
      }`}
      maskClosable={false}
      getContainer={getContainer}
      visible={visible}
      title={labelShow ? TitleComponent(title) : title}
      width={width}
      onCancel={handleCancel}
      footer={footer || null}
      centered={centered}
    >
      <Form className={styles.formContainer}>
        <FormItem label="机构名称" required>
          {getFieldDecorator('orgName', {
          })(
            <Input
              placeholder="请输入机构名称"
            />,
          )}
        </FormItem>
        <FormItem label="手机号码" required>
          {getFieldDecorator('phone', {
          })(
            <Input
              placeholder="请输入手机号码"
            />,
          )}
        </FormItem>
        <div className={styles.formWrap}>
          <FormItem label="验证码" required className={styles.itemCode}>
            {getFieldDecorator('code', {

            })(
              <Input
                placeholder="请输入验证码"
              />,
            )}
          </FormItem>
          <Button className={styles.btnCode} type="default" onClick={getCode}>{btnText}</Button>
        </div>

        <div className={styles.createApply}>
          <Button className={styles.btn} type="default" onClick={submit}>确定</Button>
        </div>
      </Form>
    </Modal>
  );
};

const FormInputForm = Form.create({ name: 'normal_login' })(FormInput);


FormInput.defaultProps = {
  title: '',
  footer: null,
  width: 520,
  visible: false,
  closeModal: () => {},
  labelShow: false,
  hasOverFlowScroll: false,
  getContainer: false,
  centered: true,
};
FormInput.propTypes = {
  title: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  footer: PropTypes.element,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  visible: PropTypes.bool,
  closeModal: PropTypes.func,
  labelShow: PropTypes.bool,
  hasOverFlowScroll: PropTypes.bool,
  getContainer: PropTypes.oneOfType([PropTypes.element, PropTypes.bool]),
  centered: PropTypes.bool,
};

export default FormInputForm;
