import request from '@/service';

// 示例文案-活动名称
export async function get_example_activity_name(params) {
  return request('/curriculum-service/activity/pintuan/get_example_activity_name', {
    method: 'get',
    data: params,
  });
}

// 新建活动
export async function create_pin_tuan(params) {
  return request('/curriculum-service/activity/pintuan/create_pin_tuan', {
    method: 'post',
    data: params,
  });
}

// 编辑活动
export async function update_pin_tuan(params) {
  return request('/curriculum-service/activity/pintuan/update_pin_tuan', {
    method: 'post',
    data: params,
  });
}

// 详情-编辑用
export async function get_pin_tuan_detail(params) {
  return request('/curriculum-service/activity/pintuan/get_pin_tuan_detail', {
    method: 'get',
    data: params,
  });
}

// 详情-预览用
export async function get_pin_tuan_detail_for_preview(params) {
  return request('/curriculum-service/activity/pintuan/get_pin_tuan_detail_for_preview', {
    method: 'get',
    data: params,
  });
}

// 活动上架
export async function on_shelf(params) {
  return request('/curriculum-service/activity/pintuan/on_shelf', {
    method: 'post',
    data: params,
  });
}

// 活动下架
export async function off_shelf(params) {
  return request('/curriculum-service/activity/pintuan/off_shelf', {
    method: 'post',
    data: params,
  });
}

// 删除活动
export async function delete_pin_tuan(params) {
  return request('/curriculum-service/activity/pintuan/delete_pin_tuan', {
    method: 'delete',
    data: params,
  });
}

// 海报模版列表-平台
export async function get_platform_poster_template_list(params) {
  return request('/curriculum-service/activity/pintuan/get_platform_poster_template_list', {
    method: 'get',
    data: params,
  });
}

// 海报模版列表-用户
export async function get_user_poster_template_list(params) {
  return request('/curriculum-service/activity/pintuan/get_user_poster_template_list', {
    method: 'get',
    data: params,
  });
}

// 海报列表
export async function list_org_poster(params) {
  return request('/curriculum-service/activity/pintuan/list_org_poster', {
    method: 'get',
    data: params,
  });
}

// 生成海报
export async function gene_poster(params) {
  return request('/curriculum-service/activity/pintuan/gene_poster', {
    method: 'post',
    data: params,
  });
}

// 删除海报
export async function del_poster(params) {
  return request('/curriculum-service/activity/pintuan/del_poster', {
    method: 'delete',
    data: params,
  });
}

// 生成海报示例
export async function gene_pin_tuan_poster_demo(params) {
  return request('curriculum-service/activity/pintuan/gene_pin_tuan_poster_demo', {
    method: 'post',
    data: params,
  });
}

// 删除海报模版
export async function del_user_poster_template(params) {
  return request('/curriculum-service/activity/pintuan/del_user_poster_template', {
    method: 'delete',
    data: params,
  });
}

// 获取预览小程序码
export async function get_preview_qr_code(params) {
  return request('/curriculum-service/activity/pintuan/get_preview_qr_code', {
    method: 'get',
    data: params,
  });
}

// 获取海报列表（含数据）
export async function list_org_poster_with_data(params) {
  return request('/curriculum-service/activity/pintuan/list_org_poster_with_data', {
    method: 'get',
    data: params,
  });
}

// 订单处理
export async function pin_tuan_order_process(params) {
  return request('curriculum-service/activity/pintuan/pin_tuan_order_process', {
    method: 'post',
    data: params,
  });
}

// 设置推广人员
export async function set_poster_extension_worker(params) {
  return request('curriculum-service/activity/pintuan/set_poster_extension_worker', {
    method: 'post',
    data: params,
  });
}
