import React, { useEffect, useState } from 'react';
import {
  Spin,
} from 'antd';
import QRCode from 'qrcode';
import { connect } from 'react-redux';
import WX from '@/assets/img/wx.svg';
import openNewPage from '@/utils/openNewPage';
import { defaultPath } from '@/config/path';
import {
  getQrCodeLoginResult
} from '../server';
import styles from '../index.module.less';

const imgArr = [
  {
    src: WX,
    alt: '微信',
    name: '微信',
  },
];

const QRLoginPage = (props) => {
  const {
    getQrCode,
    loginRegister: {
      qrCodeLoading,
    }
  } = props;

  // qr 相关
  const [qrCodeUrl, setQrCodeUrl] = useState('');

  /* eslint-disable */
  useEffect(() => {
    getQrCode((data) => {
      QRCode.toDataURL(data.qr)
        .then(url => {
          setQrCodeUrl(url);
          let timer = setInterval(() => {
            getQrCodeLoginResult({
              code: data.code
            }).then((res) => {
              if (res.code === 0) {
                if (res.data && res.data.loginSuccess) {
                  clearInterval(timer);
                  window.localStorage.setItem('token', res.data.token.token);
                  openNewPage(defaultPath, '_self');
                }
              } else {
                clearInterval(timer)
              }
            });
          }, 1000);
        })
        .catch(err => {
          console.error(err)
        })
    });
  }, [])
  /* eslint-enable */

  return (
    <>
      <div className={styles.selectLoginStyleFrame}>
        <div className={styles.selectedStatus}>
          扫码登录
        </div>
      </div>
      <div className={styles.QRCodeFrame}>
        {/* <img src={Shape} alt="二维码" /> */}
        {
          qrCodeLoading ? <Spin /> : <img src={qrCodeUrl} alt="二维码" />
        }
      </div>
      <div className={styles.textTip}>还可以使用微信app登录～</div>
      <div className={styles.loginStyleIcon}>
        {imgArr.map((item) => {
          return (
            <img
              key={item.name}
              alt={item.alt}
              {...item}
            />
          );
        })}
      </div>
    </>
  );
};

export default connect(({ loginRegister }) => ({
  loginRegister,
}), (
  {
    loginRegister:
    {
      getQrCode,
    },
  },
) => ({
  getQrCode,
}))(QRLoginPage);
