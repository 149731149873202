import React, { useState, useEffect } from 'react';
import {
  Form,
  Input,
  Icon,
  Checkbox,
  Button,
  message,
} from 'antd';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { withRouter } from 'react-router-dom';
import {
  phoneReg,
  wordAndNumReg,
  // positiveNumReg,
} from '@/utils/reg';
import { defaultPath } from '@/config/path';
import styles from '../index.module.less';

const FormItem = Form.Item;

let timer = null;

const FormInput = (props) => {
  const {
    history,
    register,
    getRegisterCheckCode,
    form: {
      getFieldDecorator,
      getFieldsValue,
      validateFields,
    },
  } = props;

  // 校验手机号判错
  const [phone] = useState('');
  // 校验登录密码判错
  const [pwd] = useState('');
  // 校验再次确认密码
  const [pwdAgain] = useState('');

  // 注册码判错
  const [inviteCode] = useState(null);

  // 手机验证码倒计时
  const [time, setTime] = useState(60);

  // 显示隐藏密码
  const [showCode, setShowCode] = useState(false);
  // 显示再次确认的隐藏密码
  const [showCodeAgain, setShowCodeAgain] = useState(false);

  // 是否同意协议
  const [isAgreement, setIsAgreement] = useState(false);

  // 获取手机验证码
  const getCode = () => {
    // console.log(111);
    // setTime(59);
    getRegisterCheckCode({
      phone: getFieldsValue().phone,
      inviteCode: getFieldsValue().inviteCode,
    }, () => {
      setTime(59);
    });
  };

  useEffect(() => {
    if (time !== 60) {
      timer = setTimeout(() => {
        setTime(time === 1 ? 60 : (time - 1));
      }, 1000);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [time]);

  // 显示隐藏密码
  const showPassword = () => {
    setShowCode(!showCode);
  };

  // 显示再一次确认的隐藏密码
  const showPasswordAgain = () => {
    setShowCodeAgain(!showCode);
  };

  // 跳转 登录页面
  const jumpLoginPage = () => {
    history.push('/login');
  };

  // 注册
  const registerApply = () => {
    validateFields((err, values) => {
      if (!err) {
        if (!values.inviteCode) {
          message.warning('请输入正确的注册码!');
          return;
        }
        if (!phoneReg.test(values.phone) || !values.phone) {
          message.warning('请输入正确的手机号!');
          return;
        }
        if (!wordAndNumReg.test(values.password) || !values.password) {
          message.warning('密码长度8-20位，包含数字以及字母!');
          return;
        }
        if (values.passwordAgain && values.password && values.passwordAgain !== values.password) {
          message.warning('确认密码与新密码不一致，请重新输入!');
          return;
        }
        const obj = {
          ...values,
        };
        delete obj.passwordAgain;
        register({
          ...obj,
        }, () => {
          // 进入正式界面
          history.push(defaultPath);
        });
      }
    });
  };

  // 改变确认状态
  const changeAgreementStatus = (e) => {
    // console.log('e', e)
    setIsAgreement(e.target.checked);
  };

  return (
    <Form className={styles.submitForm}>
      <FormItem className={styles.registerFormItem}>
        {getFieldDecorator('inviteCode', {
          // rules: [
          //   {
          //     required: true,
          //     message: '请输入注册码',
          //   },
          //   {
          //     validator: (rule, value, callback) => {
          //       if (!value) {
          //         setInviteCode(null);
          //         callback();
          //       }
          //       if (positiveNumReg.test(value)) {
          //         setInviteCode(null);
          //         callback();
          //       } else {
          //         setInviteCode('请输入正确的注册码');
          //         callback('请输入正确的注册码');
          //       }
          //     },
          //   },
          // ],
        })(
          <Input
            className={inviteCode ? styles.errorColor : styles.normalColor}
            placeholder="请输入注册码"
            onPressEnter={registerApply}
          />,
        )}
      </FormItem>
      <FormItem className={styles.registerFormItem}>
        {getFieldDecorator('phone', {
          // rules: [
          //   {
          //     required: true,
          //     message: '请输入手机号',
          //   },
          //   {
          //     validator: (rule, value, callback) => {
          //       if (!value) {
          //         setPhone('');
          //         callback();
          //       }
          //       if (phoneReg.test(value)) {
          //         setPhone('');
          //         callback();
          //       } else {
          //         setPhone('请输入正确的手机号');
          //         callback('请输入正确的手机号');
          //       }
          //     },
          //   },
          // ],
        })(
          <Input
            className={phone ? styles.errorColor : styles.normalColor}
            suffix={<Icon type="user" />}
            placeholder="请输入手机号"
            onPressEnter={registerApply}
          />,
        )}
      </FormItem>
      <FormItem
        className={classnames(styles.registerFormItem, styles.checkCodeFormItem)}
        style={
          time !== 60 ? { marginBottom: '0px' } : { marginTop: '24px', marginBottom: '16px' }
        }
      >
        {getFieldDecorator('code', {
          // rules: [
          //   {
          //     max: 6,
          //     required: true,
          //     message: '请输入手机验证码',
          //   },
          // ],
        })(
          <Input
            className={pwd ? styles.errorColor : styles.normalColor}
            addonAfter={
              <Button
                disabled={
                  time !== 60
                  || !getFieldsValue().phone
                  || !phoneReg.test(getFieldsValue().phone)
                }
                type={
                  time !== 60
                  || !getFieldsValue().phone
                  || !phoneReg.test(getFieldsValue().phone) ? 'default' : 'primary'
                }
                className={
                  time !== 60
                  || !getFieldsValue().phone
                  || !phoneReg.test(getFieldsValue().phone) ? '' : styles.enable
                }
                onClick={getCode}
              >
                获取手机验证码
              </Button>
            }
            placeholder="请输入手机验证码"
            onPressEnter={registerApply}
          />,
        )}
      </FormItem>
      {time !== 60 && (
        <div
          className={styles.checkCodeTip}
          style={{
            marginBottom: '15px',
          }}
        >
          {time}s后，重新发送验证码！
        </div>
      )}
      <FormItem className={styles.registerFormItem}>
        {getFieldDecorator('password', {
          // rules: [
          //   {
          //     required: true,
          //     message: '请填写密码',
          //   },
          //   {
          //     validator: (rule, value, callback) => {
          //       if (!value) {
          //         setPwd('');
          //         callback();
          //       }
          //       if (wordAndNumReg.test(value)) {
          //         setPwd('');
          //         callback();
          //       } else {
          //         setPwd('密码长度8-20位，包含数字以及字母');
          //         callback('密码长度8-20位，包含数字以及字母');
          //       }
          //     },
          //   },
          // ],
        })(
          <Input
            type={showCode ? 'text' : 'password'}
            className={pwd ? styles.errorColor : styles.normalColor}
            suffix={(
              <Icon
                onClick={showPassword}
                type={!showCode ? 'eye-invisible' : 'eye'}
              />
            )}
            placeholder="请输入密码"
            onPressEnter={registerApply}
          />,
        )}
      </FormItem>
      <FormItem style={{ marginBottom: '15px' }}>
        {getFieldDecorator('passwordAgain', {
          // rules: [
          //   {
          //     required: true,
          //     message: '请再次填写密码',
          //   },
          //   {
          //     validator: (rule, value, callback) => {
          //       if (value && getFieldsValue().password && (value !== getFieldsValue().password)) {
          //         setPwdAgain('确认密码与新密码不一致，请重新输入');
          //         callback('确认密码与新密码不一致，请重新输入');
          //       } else {
          //         setPwdAgain('');
          //         callback();
          //       }
          //     },
          //   },
          // ],
        })(
          <Input
            type={showCodeAgain ? 'text' : 'password'}
            className={pwdAgain ? styles.errorColor : styles.normalColor}
            suffix={(
              <Icon
                onClick={showPasswordAgain}
                type={!showCodeAgain ? 'eye-invisible' : 'eye'}
              />
            )}
            placeholder="再次确认密码"
            onPressEnter={registerApply}
          />,
        )}
      </FormItem>
      <div className={styles.codeRealtion}>
        <div className={styles.rememberCode}>
          <Checkbox
            checked={isAgreement}
            onChange={(e) => { changeAgreementStatus(e); }}
            style={{ marginRight: '9px' }}
          /> 已阅读并同意
          <a target="_blank" rel="noreferrer" href="/agreements/useAgreement.html">《用户协议》</a>
          <a target="_blank" rel="noreferrer" href="/agreements/privacyAgreement.html">《隐私政策》</a>
        </div>
      </div>
      <div className={styles.registerButton}>
        {isAgreement ? (
          <Button type="primary" onClick={registerApply}>注册 </Button>
        ) : (
          <Button type="default" disabled>注册 </Button>
        )}
      </div>
      <div
        className={styles.registerLoginText}
        onClick={jumpLoginPage}
      >
        已有账号，立即登录
      </div>
    </Form>
  );
};

const FormInputForm = Form.create({ name: 'normal_register' })(FormInput);

FormInputForm.defaultProps = {

};
FormInputForm.propTypes = {

};

export default withRouter(connect(({ loginRegister }) => ({
  loginRegister,
}), (
  {
    loginRegister:
    {
      register,
      getRegisterCheckCode,
    },
  },
) => ({
  register,
  getRegisterCheckCode,
}))(FormInputForm));
