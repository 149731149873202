import request from '@/service';

// 登录
export async function loginByPassword(params) {
  return request('/organization-service/account/account_login_with_password', {
    method: 'post',
    data: params,
  });
}

export async function loginByCode(params) {
  return request('/organization-service/account/account_login_with_code', {
    method: 'post',
    data: params,
  });
}

// 注册
export async function register(params) {
  return request('/organization-service/account/registry_account', {
    method: 'post',
    data: params,
  });
}

// 获取注册验证码
export async function getRegisterCheckCode(params) {
  return request('/organization-service/account/send_registry_sms_code', {
    method: 'post',
    data: params,
  });
}

// 获取登录验证码
export async function getLoginCheckCode(params) {
  return request('/organization-service/account/send_login_sms_code', {
    method: 'post',
    data: params,
    returnInfo: true,
  });
}

// 获取二维码
export async function getQrCode(params) {
  return request('/organization-service/account/get_we_chat_qr_code_for_login', {
    method: 'get',
    data: params,
  });
}

// 轮询微信登录结果
export async function getQrCodeLoginResult(params) {
  return request('/organization-service/account/polling_login_with_we_chat_result', {
    method: 'get',
    data: params,
  });
}


// 免费试用
export async function freeTrial(params) {
  return request('/organization-service/free_trial/submit', {
    method: 'post',
    data: params,
  });
}

// 免费试用-发送验证码
export async function sendFreeTrialCode(params) {
  return request('/organization-service/free_trial/send_free_trial_code', {
    method: 'post',
    data: params,
  });
}
