import request from '@/service';

// 新建班级
export async function createClass(params) {
  return request('/person-service/team/create_team_info', {
    method: 'post',
    data: params,
  });
}

// 修改班级信息
export async function updateClass(params) {
  return request('/person-service/team/update_team_info', {
    method: 'post',
    data: params,
  });
}

// 删除班级信息
export async function deleteClass(params) {
  return request('/person-service/team/remove_team_info', {
    method: 'post',
    data: params,
  });
}

// 班级添加学生
export async function addStudent(params) {
  return request('/person-service/team/add_student_to_team', {
    method: 'post',
    data: params,
  });
}

// 班级删除学生
export async function deleteStudent(params) {
  return request('/person-service/team/remove_student_from_team', {
    method: 'post',
    data: params,
  });
}
