import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  Icon,
} from 'antd'
// import LoginLabel from '@/assets/img/loginLabel.png';
import { ReactComponent as LoginLabel } from '@/assets/img/goodclasslogo.svg';
// import LoginLabel from '@/assets/img/logo.PNG';

import { ReactComponent as loginBgDc } from '@/assets/img/login-bg-dc.svg';
import { ReactComponent as loginBgGood } from '@/assets/img/login-bg-good.svg';
import { ReactComponent as loginBgBook } from '@/assets/img/login-bg-book.svg';
import { ReactComponent as loginBgClass } from '@/assets/img/login-bg-class.svg';
import { ReactComponent as loginBgMonitor } from '@/assets/img/login-bg-monitor.svg';
import { ReactComponent as loginBgNotebook } from '@/assets/img/login-bg-notebook.svg';
import { ReactComponent as loginBgPhone } from '@/assets/img/login-bg-phone.svg';
import { ReactComponent as loginBgScore } from '@/assets/img/login-bg-score.svg';
import { ReactComponent as loginBgVideo } from '@/assets/img/login-bg-video.svg';

import PageLoading from '@/components/PageLoading';
import Login from './login';
import Register from './register';
import Footer from '../commonToolsComponent/Footer';
import styles from './index.module.less';

const CommonPage = (props) => {
  const {
    history,
    location: {
      pathname,
    },
    loginRegister: {
      loading,
    },
  } = props;

  // 用户当前状态 0 登录; 1 注册
  const [userStatus, setUserStatus] = useState(pathname === '/register' ? 1 : 0);

  useEffect(() => {
    setUserStatus(pathname === '/register' ? 1 : 0);
  }, [pathname]);

  // 改变用户状态
  const changeUserStatus = (code) => {
    setUserStatus(code);
    history.push(code === 0 ? '/login' : '/register');
  };

  return (
    <>
      {loading ? (
        <PageLoading />
      ) : (
        <div className={styles.loginContainer}>
          {/* 背景图 icon */}
          <Icon className={styles.loginBgDc} component={loginBgDc} />
          <Icon className={styles.loginBgGood} component={loginBgGood} />
          <Icon className={styles.loginBgScore} component={loginBgScore} />
          <Icon className={styles.loginBgNotebook} component={loginBgNotebook} />
          <Icon className={styles.loginBgPhone} component={loginBgPhone} />
          <Icon className={styles.loginBgMonitor} component={loginBgMonitor} />
          <Icon className={styles.loginBgVideo} component={loginBgVideo} />
          <Icon className={styles.loginBgScoreMini} component={loginBgScore} />
          <Icon className={styles.loginBgBook} component={loginBgBook} />
          <Icon className={styles.loginBgClass} component={loginBgClass} />
          {/* label 图 */}
          <div className={styles.loginLabelFrame}>
            <Icon component={LoginLabel} style={{ fontSize: '100px' }} />
            {/* <img src={LoginLabel} alt="goodclass" /> */}
          </div>
          {/* 文本 */}
          <div className={styles.loginLabelText}>
            GoodClass
          </div>
          <div
            className={userStatus === 0 ? styles.loginFormFrame : styles.registerFormFrame}
          >
            {/* 判断登录注册 */}
            {userStatus === 0 ? <Login /> : <Register />}
            <div className={styles.switchLoginOrRegister}>
              <div
                className={userStatus === 0 ? styles.userActiveStatus : styles.userCommonStaus}
                onClick={() => { changeUserStatus(0); }}
              >
                登录
              </div>
              <div
                className={userStatus === 1 ? styles.userActiveStatus : styles.userCommonStaus}
                onClick={() => { changeUserStatus(1); }}
              >
                注册
              </div>
            </div>
          </div>
          <Footer />
        </div>
      )}
    </>
  );
};

export default withRouter(connect(({ loginRegister }) => ({
  loginRegister,
}))(CommonPage));
