import request from '@/service';

// 获取教室列表
export async function getClassRoomList(params) {
  return request('/organization-service/class_room/list_class_room_by_user_id_with_page', {
    method: 'get',
    data: params,
  });
}

// 获取全量教室列表
export async function getAllClassRoom(params) {
  return request('/organization-service/class_room/list_all_class_room', {
    method: 'get',
    data: params,
  });
}

// 新建教室
export async function createClassRoom(params) {
  return request('/organization-service/class_room/create_class_room', {
    method: 'post',
    data: params,
  });
}

// 获取教室信息
export async function getClassRoomInfo(params) {
  return request('/organization-service/class_room/class_room_info_by_id', {
    method: 'get',
    data: params,
  });
}

// 修改教室信息
export async function updateClassRoom(params) {
  return request('/organization-service/class_room/update_class_room', {
    method: 'post',
    data: params,
  });
}

// 删除教室信息
export async function deleteClassRoom(params) {
  return request('/organization-service/class_room/delete_class_room_by_id', {
    method: 'delete',
    data: params,
  });
}
