import * as server from './server';
import { message } from 'antd';

export default {
  state: {
    loading: false,

    CLASS_HOUR_TYPE: [],
    refreshTime: new Date().getTime(),
  },
  reducers: {
    setState(state, payload) {
      Object.keys(payload).forEach((item) => {
        state[item] = payload[item];
      });
    },
  },
  effects: () => ({

    // 字典项调用
    async getDict(payload, rootStates, cb) {
      const { code, data } = await server.getTypeSelect(payload);
      if (code === 0) {
        this.setState({
          [payload.code]: data,
        });
      }
      cb && cb();
    },
    // 设置续费意向
    async set_renew_intention(payload, rootStates, cb) {
      this.setState({
        buttonLoading: true,
      });
      const { code } = await server.set_renew_intention(payload);
      if (code === 0) {
        message.success('设置成功');
        cb && cb();
      }
      this.setState({
        buttonLoading: false,
      });
    },

    // 刷新页面
    refresh() {
      this.setState({
        refreshTime: new Date().getTime(),
      });
    },
  }),
};
