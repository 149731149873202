import { message } from 'antd';
import * as server from './server';

export default {
  state: {
    loading: false,
    list: [],
    majorDict: [],
    shopDetail: {},

    createButtonLoading: false,
    deleteButtonLoading: false,
    detailLoading: false,
  },
  reducers: {
    setState(state, payload) {
      Object.keys(payload).forEach((item) => {
        state[item] = payload[item];
      });
    },
  },
  effects: () => ({
    // 获取机构主营字典项
    async getShopMajorDict(payload, rootStates, cb) {
      const { code, data } = await server.getShopMajorDict(payload);
      if (code === 0) {
        this.setState({
          majorDict: data || [],
        });
        cb && cb();
      }
    },
    // 获取机构列表
    async getOrganization(payload, rootStates, cb) {
      const { code, data } = await server.getOrganization(payload);
      if (code === 0) {
        this.setState({
          list: (data && data.records) ? data.records : [],
        });
        cb && cb();
      }
    },
    // 获取机构列表
    async list_organization_by_user_id(payload, rootStates, cb) {
      const { code, data } = await server.list_organization_by_user_id(payload);
      if (code === 0) {
        this.setState({
          list: data || [],
        });
        cb && cb();
      }
    },
    // 创建机构(v2)
    async createOrg(payload, rootStates, cb) {
      this.setState({
        createButtonLoading: true,
      });
      if (payload.id) {
        const { code } = await server.update_organization_v2(payload);
        if (code === 0) {
          message.success('修改成功');
          cb && cb();
        }
        this.setState({
          createButtonLoading: false,
        });
      } else {
        const { code } = await server.create_organization_v2(payload);
        if (code === 0) {
          message.success('创建成功');
          cb && cb();
        }
        this.setState({
          createButtonLoading: false,
        });
      }
    },
    // 删除机构
    async deleteOrganization(payload, rootStates, cb) {
      this.setState({
        deleteButtonLoading: true,
      });
      const { code } = await server.deleteOrganization(payload);
      if (code === 0) {
        message.success('删除成功');
        cb && cb();
      }
      this.setState({
        deleteButtonLoading: false,
      });
    },
    // 获取机构详情
    // async getOrganizationDetail(payload, rootStates, cb) {
    //   this.setState({
    //     detailLoading: true,
    //   });
    //   const { code, data } = await server.getOrganizationDetail(payload);
    //   if (code === 0) {
    //     this.setState({
    //       shopDetail: data,
    //     });
    //     cb && cb(data);
    //   }
    //   this.setState({
    //     detailLoading: false,
    //   });
    // },
  }),
};
