import request from '@/service';

// 获取机构列表
export async function getOrganization(params) {
  return request('/organization-service/organization/list_organization_by_user_id_with_page', {
    method: 'get',
    data: params,
  });
}

// 获取机构列表（不分页）
export async function list_organization_by_user_id(params) {
  return request('/organization-service/organization/list_organization_by_user_id', {
    method: 'get',
    data: params,
  });
}

// 创建机构信息v2
export async function create_organization_v2(params) {
  return request('/organization-service/organization/create_organization_v2', {
    method: 'post',
    data: params,
  });
}

// 更新机构信息v2
export async function update_organization_v2(params) {
  return request('/organization-service/organization/update_organization_v2', {
    method: 'post',
    data: params,
  });
}

// 获取学科列表
export async function get_subject_list(params) {
  return request('/organization-service/common/get_subject_list', {
    method: 'get',
    data: params,
  });
}

// 获取主营字典项
export async function getShopMajorDict(params) {
  return request('/organization-service/dict/get_drop_down_list', {
    method: 'get',
    data: params,
  });
}

// 删除机构
export async function deleteOrganization(params) {
  return request('/organization-service/organization/delete_organization', {
    method: 'delete',
    data: params,
  });
}

// 获取机构详情
export async function getOrganizationDetail(params) {
  return request('/organization-service/organization/get_detail_by_id', {
    method: 'get',
    data: params,
  });
}

// 获取机构详情
export async function getOrgInfo(params) {
  return request('/organization-service/organization/organization_info_by_id', {
    method: 'get',
    data: params,
  });
}
