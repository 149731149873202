import { message } from 'antd';
import * as server from './server';

export default {
  state: {
    loading: false,
    list: [],
    courseList: [], // 课程列表
    createButtonLoading: false,
    detailLoading: false,
    refreshTime: new Date().getTime(),
  },
  reducers: {
    setState(state, payload) {
      Object.keys(payload).forEach((item) => {
        state[item] = payload[item];
      });
    },
  },
  effects: () => ({
    // 新建活动
    async create_pin_tuan(payload, rootStates, cb) {
      this.setState({
        createButtonLoading: true,
      });
      const { code, data } = await server.create_pin_tuan(payload);
      if (code === 0) {
        message.success('保存成功');
        cb && cb(data);
      }
      this.setState({
        createButtonLoading: false,
      });
    },
    // 编辑活动
    async update_pin_tuan(payload, rootStates, cb) {
      this.setState({
        createButtonLoading: true,
      });
      const { code, data } = await server.update_pin_tuan(payload);
      if (code === 0) {
        message.success('更新成功');
        cb && cb(data);
      }
      this.setState({
        createButtonLoading: false,
      });
    },
    // 详情-编辑用
    async get_pin_tuan_detail(payload, rootStates, cb) {
      this.setState({
        detailLoading: true,
      });
      const { code, data } = await server.get_pin_tuan_detail(payload);
      if (code === 0) {
        cb && cb(data);
      }
      this.setState({
        detailLoading: false,
      });
    },
    // 详情-预览用
    async get_pin_tuan_detail_for_preview(payload, rootStates, cb) {
      this.setState({
        detailLoading: true,
      });
      const { code, data } = await server.get_pin_tuan_detail_for_preview(payload);
      if (code === 0) {
        cb && cb(data);
      }
      this.setState({
        detailLoading: false,
      });
    },
    // 活动上架
    async on_shelf(payload, rootStates, cb) {
      this.setState({
        buttonLoading: true,
      });
      const { code } = await server.on_shelf(payload);
      if (code === 0) {
        message.success('上架成功');
        this.refresh();
        cb && cb();
      }
      this.setState({
        buttonLoading: false,
      });
    },
    // 活动下架
    async off_shelf(payload, rootStates, cb) {
      this.setState({
        buttonLoading: true,
      });
      const { code } = await server.off_shelf(payload);
      if (code === 0) {
        message.success('下架成功');
        this.refresh();
        cb && cb();
      }
      this.setState({
        buttonLoading: false,
      });
    },
    // 删除活动
    async delete_pin_tuan(payload, rootStates, cb) {
      this.setState({
        buttonLoading: true,
      });
      const { code } = await server.delete_pin_tuan(payload);
      if (code === 0) {
        this.refresh();
        cb && cb();
      }
      this.setState({
        buttonLoading: false,
      });
    },
    // 海报模版列表-平台
    async get_platform_poster_template_list(payload, rootStates, cb) {
      this.setState({
        detailLoading: true,
      });
      const { code, data } = await server.get_platform_poster_template_list(payload);
      if (code === 0) {
        cb && cb(data);
      }
      this.setState({
        detailLoading: false,
      });
    },
    // 海报模版列表-用户
    async get_user_poster_template_list(payload, rootStates, cb) {
      this.setState({
        detailLoading: true,
      });
      const { code, data } = await server.get_user_poster_template_list(payload);
      if (code === 0) {
        cb && cb(data);
      }
      this.setState({
        detailLoading: false,
      });
    },
    // 海报列表
    async list_org_poster(payload, rootStates, cb) {
      this.setState({
        detailLoading: true,
      });
      const { code, data } = await server.list_org_poster(payload);
      if (code === 0) {
        cb && cb(data);
      }
      this.setState({
        detailLoading: false,
      });
    },
    // 生成海报
    async gene_poster(payload, rootStates, cb) {
      this.setState({
        buttonLoading: true,
      });
      const { code } = await server.gene_poster(payload);
      if (code === 0) {
        this.refresh();
        message.success('生成成功');
        cb && cb();
      }
      this.setState({
        buttonLoading: false,
      });
    },
    // 删除海报
    async del_poster(payload, rootStates, cb) {
      this.setState({
        buttonLoading: true,
      });
      const { code } = await server.del_poster(payload);
      if (code === 0) {
        message.success('删除成功');
        cb && cb();
      }
      this.setState({
        buttonLoading: false,
      });
    },
    // 示例文案-活动名称
    async get_example_activity_name(payload, rootStates, cb) {
      this.setState({
        buttonLoading: true,
      });
      const { code } = await server.get_example_activity_name(payload);
      if (code === 0) {
        cb && cb();
      }
      this.setState({
        buttonLoading: false,
      });
    },
    // 生成海报示例
    async gene_pin_tuan_poster_demo(payload, rootStates, cb) {
      this.setState({
        buttonLoading: true,
      });
      const { code } = await server.gene_pin_tuan_poster_demo(payload);
      if (code === 0) {
        this.refresh();
        message.success('生成成功');
        cb && cb();
      }
      this.setState({
        buttonLoading: false,
      });
    },
    // 删除海报模版
    async del_user_poster_template(payload, rootStates, cb) {
      this.setState({
        buttonLoading: true,
      });
      const { code } = await server.del_user_poster_template(payload);
      if (code === 0) {
        message.success('删除成功');
        cb && cb();
      }
      this.setState({
        buttonLoading: false,
      });
    },
    // 获取预览小程序码
    async get_preview_qr_code(payload, rootStates, cb) {
      this.setState({
        buttonLoading: true,
      });
      const { code, data } = await server.get_preview_qr_code(payload);
      if (code === 0) {
        cb && cb(data);
      }
      this.setState({
        buttonLoading: false,
      });
    },
    // 获取海报列表（含数据）
    async list_org_poster_with_data(payload, rootStates, cb) {
      this.setState({
        buttonLoading: true,
      });
      const { code, data } = await server.list_org_poster_with_data(payload);
      if (code === 0) {
        cb && cb(data);
      }
      this.setState({
        buttonLoading: false,
      });
    },
    // 订单处理
    async pin_tuan_order_process(payload, rootStates, cb) {
      this.setState({
        buttonLoading: true,
      });
      const { code, data } = await server.pin_tuan_order_process(payload);
      if (code === 0) {
        this.refresh();
        cb && cb(data);
      }
      this.setState({
        buttonLoading: false,
      });
    },
    // 设置推广人员
    async set_poster_extension_worker(payload, rootStates, cb) {
      this.setState({
        buttonLoading: true,
      });
      const { code, data } = await server.set_poster_extension_worker(payload);
      if (code === 0) {
        cb && cb(data);
      }
      this.setState({
        buttonLoading: false,
      });
    },
    // 刷新页面
    refresh() {
      this.setState({
        refreshTime: new Date().getTime(),
      });
    },
  }),
};
