import * as server from './service';
// 项目共用model
export default {
  state: {
    COVER_SUBJECT: [], // 机构覆盖学科
  },
  reducers: {
    setState(state, payload) {
      Object.keys(payload).forEach((item) => {
        state[item] = payload[item];
      });
    },
  },
  effects: () => ({
    // 获取学科下拉项
    async get_cover_subject(payload, rootStates, cb) {
      const { code, data } = await server.get_cover_subject(payload);
      if (code === 0) {
        this.setState({
          COVER_SUBJECT: data || [],
        });
        cb && cb();
      }
    },
  }),
};
