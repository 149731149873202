import request from '@/service';

// 新建课件
export async function createCourseware(params) {
  return request('/curriculum-service/document_info/create_document_info', {
    method: 'post',
    data: params,
  });
}

// // 获取教室信息
// export async function getCoursewareInfo(params) {
//   return request('/organization-service/class_room/class_room_info_by_id', {
//     method: 'get',
//     data: params,
//   });
// }

// 修改教室信息
export async function updateCourseware(params) {
  return request('/curriculum-service/document_info/update_document_info', {
    method: 'post',
    data: params,
  });
}

// 删除教室信息
export async function deleteCourseware(params) {
  return request('/curriculum-service/document_info/delete_document_info', {
    method: 'delete',
    data: params,
  });
}
