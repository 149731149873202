import request from '@/service';

// 获取下拉类型字典项
export async function getTypeSelect(params) {
  return request('/organization-service/dict/get_drop_down_list', {
    method: 'get',
    data: params,
  });
}

// 新建 followup
export async function createFollowup(params) {
  return request('/person-service/followup/create_followup_for_leads', {
    method: 'post',
    data: params,
  });
}

// 获取目的
export async function getContactPurposeTypeList(params) {
  return request('/person-service/leads_contact_purpose_code/list_all', {
    method: 'get',
    data: params,
  });
}

// 创建试听
export async function createTrialRecord(params) {
  return request('/person-service/trial_record/create_trial_record', {
    method: 'post',
    data: params,
  });
}

// 获取线索信息
export async function getDetail(params) {
  return request('/person-service/leads_info/leads_info_detail', {
    method: 'get',
    data: params,
  });
}

// 转为学员
export async function turnToStudent(params) {
  return request('/person-service/leads_info/transfer_to_student', {
    method: 'post',
    data: params,
  });
}

// 获取线索配置
export async function get_leads_config(params) {
  return request('/person-service/leads_info/get_leads_config', {
    method: 'get',
    data: params,
  });
}

// 设置是否接收课前提醒
export async function set_before_class_remind(params) {
  return request('/person-service/leads_info/set_before_class_remind', {
    method: 'post',
    data: params,
  });
}

// 设置是否接收课时变更提醒
export async function set_classhour_change_remind(params) {
  return request('/person-service/leads_info/set_classhour_change_remind', {
    method: 'post',
    data: params,
  });
}

// 设置是否接收课堂评价提醒
export async function set_class_evaluation_remind(params) {
  return request('/person-service/student_info/set_class_evaluation_remind', {
    method: 'post',
    data: params,
  });
}

// 线索详情-设置-课后作业提醒
export async function set_homework_remind(params) {
  return request('/person-service/leads_info/set_homework_remind', {
    method: 'post',
    data: params,
  });
}


// 取消回访
export async function cancel_revisit(params) {
  return request('/person-service/followup/cancel_revisit', {
    method: 'post',
    data: params,
  });
}

// 线索详情-跟进
export async function follow_v2(params) {
  return request('/person-service/follow_record/follow_v2', {
    method: 'post',
    data: params,
  });
}


// 释放线索
export async function unfollow(params) {
  return request('/person-service/follow_record/unfollow', {
    method: 'post',
    data: params,
  });
}

// 获取线索来源
export async function getLeadsSourceTypeList(params) {
  return request('/person-service/leads_source_code/list_all', {
    method: 'get',
    data: params,
  });
}

// 根据手机号查询家长
export async function getParentByPhone(params) {
  return request('/person-service/parent_info/get_parent_by_phone', {
    method: 'get',
    data: params,
  });
}

// 修改线索信息
export async function updateStudent(params) {
  return request('/person-service/leads_info/update_leads_info', {
    method: 'post',
    data: params,
  });
}

// 新建线索
export async function createStudent(params) {
  return request('/person-service/leads_info/create_leads_info', {
    method: 'post',
    data: params,
  });
}

// 获取简要学生信息
export async function getStudentInfo(params) {
  return request('/person-service/student_info/get_student_simple_info', {
    method: 'get',
    data: params,
  });
}

// 线索详情-沟通列表
export async function list_followup_with_date_by_student(params) {
  return request('/person-service/followup/list_followup_with_date_by_student', {
    method: 'get',
    data: params,
  });
}

// 更新沟通内容
export async function update_followup_content(params) {
  return request('/person-service/followup/update_followup_content', {
    method: 'post',
    data: params,
  });
}

// 线索详情-设置-删除线索
export async function delete_leads_info(params) {
  return request('/person-service/leads_info/delete_leads_info', {
    method: 'delete',
    data: params,
  });
}
