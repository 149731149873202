import request from '@/service';

// 全量线上售卖课程列表
export async function list_all_online_course_info(params) {
  return request('/curriculum-service/online_shop/course_info/list_all_online_course_info', {
    method: 'get',
    data: params,
  });
}

// 示例文案-活动名称
export async function get_example_activity_name(params) {
  return request('/curriculum-service/activity/miaosha/get_example_activity_name', {
    method: 'get',
    data: params,
  });
}

// 新建秒杀活动
export async function create_miao_sha(params) {
  return request('/curriculum-service/activity/miaosha/create_miao_sha', {
    method: 'post',
    data: params,
  });
}

// 编辑秒杀活动
export async function update_miao_sha(params) {
  return request('/curriculum-service/activity/miaosha/update_miao_sha', {
    method: 'post',
    data: params,
  });
}

// 秒杀详情-编辑用
export async function get_miao_sha_detail(params) {
  return request('/curriculum-service/activity/miaosha/get_miao_sha_detail', {
    method: 'get',
    data: params,
  });
}

// 秒杀详情-预览用
export async function get_miao_sha_detail_for_preview(params) {
  return request('/curriculum-service/activity/miaosha/get_miao_sha_detail_for_preview', {
    method: 'get',
    data: params,
  });
}

// 秒杀活动上架
export async function miao_sha_on_shelf(params) {
  return request('/curriculum-service/activity/miaosha/on_shelf', {
    method: 'post',
    data: params,
  });
}

// 秒杀活动下架
export async function miao_sha_off_shelf(params) {
  return request('/curriculum-service/activity/miaosha/off_shelf', {
    method: 'post',
    data: params,
  });
}

// 删除秒杀活动
export async function delete_miao_sha(params) {
  return request('/curriculum-service/activity/miaosha/delete_miao_sha', {
    method: 'delete',
    data: params,
  });
}

// 秒杀海报模版列表-平台
export async function get_platform_poster_template_list(params) {
  return request('/curriculum-service/activity/miaosha/get_platform_poster_template_list', {
    method: 'get',
    data: params,
  });
}

// 秒杀海报模版列表-用户
export async function get_user_poster_template_list(params) {
  return request('/curriculum-service/activity/miaosha/get_user_poster_template_list', {
    method: 'get',
    data: params,
  });
}

// 秒杀海报列表
export async function list_org_poster(params) {
  return request('/curriculum-service/activity/miaosha/list_org_poster', {
    method: 'get',
    data: params,
  });
}

// 秒杀生成海报
export async function miao_sha_gene_poster(params) {
  return request('/curriculum-service/activity/miaosha/gene_poster', {
    method: 'post',
    data: params,
  });
}

// 删除秒杀海报
export async function del_poster(params) {
  return request('/curriculum-service/activity/miaosha/del_poster', {
    method: 'delete',
    data: params,
  });
}

// 生成秒杀海报示例
export async function gene_miao_sha_poster_demo(params) {
  return request('curriculum-service/activity/miaosha/gene_miao_sha_poster_demo', {
    method: 'post',
    data: params,
  });
}

// 删除海报模版
export async function del_user_poster_template(params) {
  return request('/curriculum-service/activity/miaosha/del_user_poster_template', {
    method: 'delete',
    data: params,
  });
}

// 获取预览小程序码
export async function get_preview_qr_code(params) {
  return request('/curriculum-service/activity/miaosha/get_preview_qr_code', {
    method: 'get',
    data: params,
  });
}

// 获取海报列表（含数据）
export async function list_org_poster_with_data(params) {
  return request('/curriculum-service/activity/miaosha/list_org_poster_with_data', {
    method: 'get',
    data: params,
  });
}

// 秒杀订单处理
export async function miao_sha_order_process(params) {
  return request('curriculum-service/activity/miaosha/miao_sha_order_process', {
    method: 'post',
    data: params,
  });
}

// 设置推广人员
export async function set_poster_extension_worker(params) {
  return request('curriculum-service/activity/miaosha/set_poster_extension_worker', {
    method: 'post',
    data: params,
  });
}

// 添加线上展示的机构教师
export async function add_org_teacher(params) {
  return request('/organization-service/online_shop/organization/add_org_teacher', {
    method: 'post',
    data: params,
  });
}

// 移除线上展示的机构教师
export async function remove_org_teacher(params) {
  return request('/organization-service/online_shop/organization/remove_org_teacher', {
    method: 'post',
    data: params,
  });
}
