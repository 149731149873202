import { message } from 'antd';
import * as server from './server';

export default {
  state: {
    loading: false,
    list: [],
    courseInfo: {},

    SUBJECT: [],
    TEAM_MODE: [],
    TEACH_MODE: [],
    ONLINE_CLASS_MODE: [],
    CLASS_HOUR_TYPE: [],
    COURSE_SERVICE_INFO: [],

    teacherList: [],
    assistantList: [],
    allCourse: [], // 全量课程
    allCourseForOrder: [], // 全量课程列表，创建订单使用
    allSyllabus: [], // 全量课程大纲

    createButtonLoading: false,
    deleteButtonLoading: false,
    detailLoading: false,

    buttonLoading: false,

    refreshTime: new Date().getTime(),

    outlineList: [],
  },
  reducers: {
    setState(state, payload) {
      Object.keys(payload).forEach((item) => {
        state[item] = payload[item];
      });
    },
  },
  effects: () => ({
    // 获取课件列表下拉项
    async getSelect(payload, rootStates, cb) {
      const { code, data } = await server.getSelect(payload);
      if (code === 0) {
        this.setState({
          [payload.code]: data || [],
        });
        cb && cb();
      }
    },
    // 获取全量课程下拉项
    async getAllCourse(payload, rootStates, cb) {
      const { code, data } = await server.getAllCourse(payload);
      if (code === 0) {
        this.setState({
          allCourse: data || [],
        });
        cb && cb();
      }
    },
    // 获取全量课程下拉项(创建订单使用)
    async getAllCourseForOrder(payload, rootStates, cb) {
      const { code, data } = await server.getAllCourseForOrder(payload);
      if (code === 0) {
        this.setState({
          allCourseForOrder: data || [],
        });
        cb && cb();
      }
    },
    // 获取全量大纲下拉项
    async getAllSyllabus(payload, rootStates, cb) {
      const { code, data } = await server.getAllSyllabus(payload);
      if (code === 0) {
        this.setState({
          allSyllabus: data || [],
        });
        cb && cb();
      }
    },
    // 创建修改课程
    async createCourse(payload, rootStates, cb) {
      this.setState({
        createButtonLoading: true,
      });
      if (payload.id) {
        const { code } = await server.updateCourse(payload);
        if (code === 0) {
          message.success('修改成功');
          cb && cb();
        }
        this.setState({
          createButtonLoading: false,
        });
      } else {
        const { code } = await server.createCourse(payload);
        if (code === 0) {
          message.success('创建成功');
          cb && cb();
        }
        this.setState({
          createButtonLoading: false,
        });
      }
    },
    // 上架或者下架操作
    async upOrdownShelf(payload, rootStates, cb) {
      this.setState({
        buttonLoading: true,
      });
      const { code } = await server.upOrdownShelf(payload);
      if (code === 0) {
        if (payload.status === 'ON_SHELF') {
          message.success('上架成功');
        } else if (payload.status === 'NOT_ON_SHELF') {
          message.success('下架成功');
        }
        cb && cb();
      }
      this.setState({
        buttonLoading: false,
      });
    },

    // 获取课程简要详情
    async getCourseInfo(payload, rootStates, cb) {
      this.setState({
        detailLoading: true,
      });
      const { code, data } = await server.getCourseInfo(payload);
      if (code === 0) {
        this.setState({
          courseInfo: data,
        });
        cb && cb(data);
      }
      this.setState({
        detailLoading: false,
      });
    },

    // 获取课程大纲列表
    async getOutlineList(payload, rootStates, cb) {
      const { code, data } = await server.getOutlineList(payload);
      if (code === 0) {
        this.setState({
          outlineList: payload.syllabusName ? data : [],
        });
      }
      cb && cb();
    },

    // 刷新页面
    refresh() {
      this.setState({
        refreshTime: new Date().getTime(),
      });
    },
  }),
};
