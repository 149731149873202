import { message } from 'antd';
import * as server from './server';

export default {
  state: {
    loading: false,
    list: [],
    teacherDetail: {},
    teacherList: [], // 全量教师信息列表

    createButtonLoading: false,
    deleteButtonLoading: false,
    detailLoading: false,
    buttonLoading: false,

    refreshTime: new Date().getTime(),

    giveValueTime: new Date().getTime(),

    TEACHER_TAG: [],
    LEARN_CYCLE: [],
    TEACH_TYPE: [],
    SUBJECT: [],
    TEACHER_STATUS: [],
  },
  reducers: {
    setState(state, payload) {
      Object.keys(payload).forEach((item) => {
        state[item] = payload[item];
      });
    },
  },
  effects: () => ({
    // 字典项调用
    async getDict(payload, rootStates, cb) {
      const { code, data } = await server.getTypeSelect(payload);
      if (code === 0) {
        this.setState({
          [payload.code]: data,
        });
      }
      cb && cb();
    },
    // 全量教师信息列表
    async getAllTeacher(payload, rootStates, cb) {
      const { code, data } = await server.getAllTeacher(payload);
      if (code === 0) {
        this.setState({
          teacherList: data,
        });
      }
      cb && cb();
    },
    // 创建修改教师
    async createTeacher(payload, rootStates, cb) {
      this.setState({
        createButtonLoading: true,
      });
      if (payload.id) {
        const { code } = await server.updateTeacher(payload);
        if (code === 0) {
          message.success('修改成功');
          cb && cb();
        }
        this.setState({
          createButtonLoading: false,
        });
      } else {
        const { code } = await server.createTeacher(payload);
        if (code === 0) {
          message.success('创建成功');
          cb && cb();
        }
        this.setState({
          createButtonLoading: false,
        });
      }
    },
    // 删除教师
    async deleteTeacher(payload, rootStates, cb) {
      this.setState({
        deleteButtonLoading: true,
      });
      const { code } = await server.deleteTeacher(payload);
      if (code === 0) {
        message.success('删除成功');
        cb && cb();
      }
      this.setState({
        deleteButtonLoading: false,
      });
    },
    // 获取教师详情
    async getTeacherInfo(payload, rootStates, cb) {
      this.setState({
        detailLoading: true,
      });
      const { code, data } = await server.getTeacherInfo(payload);
      if (code === 0) {
        this.setState({
          teacherDetail: data,
          giveValueTime: new Date().getTime(),
        });
        cb && cb(data);
      }
      this.setState({
        detailLoading: false,
      });
    },

    // 创建修改教师经历
    async createExperience(payload, rootStates, cb) {
      this.setState({
        buttonLoading: true,
      });
      if (payload.id) {
        const { code } = await server.updateExperience(payload);
        if (code === 0) {
          message.success('修改成功');
          cb && cb();
        }
      } else {
        const { code } = await server.addExperience(payload);
        if (code === 0) {
          message.success('创建成功');
          cb && cb();
        }
      }
      this.setState({
        buttonLoading: false,
      });
    },
    // 删除教师经历
    async deleteExperience(payload, rootStates, cb) {
      const { code } = await server.deleteExperience(payload);
      if (code === 0) {
        message.success('删除成功');
        cb && cb();
      }
    },

    // 创建修改教师荣誉
    async createHonour(payload, rootStates, cb) {
      this.setState({
        buttonLoading: true,
      });
      if (payload.id) {
        const { code } = await server.updateHonour(payload);
        if (code === 0) {
          message.success('修改成功');
          cb && cb();
        }
      } else {
        const { code } = await server.addHonour(payload);
        if (code === 0) {
          message.success('创建成功');
          cb && cb();
        }
      }
      this.setState({
        buttonLoading: false,
      });
    },
    // 删除教师经历
    async deleteHonour(payload, rootStates, cb) {
      const { code } = await server.deleteHonour(payload);
      if (code === 0) {
        message.success('删除成功');
        cb && cb();
      }
    },

    // 刷新页面
    refresh() {
      this.setState({
        refreshTime: new Date().getTime(),
      });
    },

    // 清除缓存 detail
    clearDetail() {
      this.setState({
        teacherDetail: {},
      });
    },
  }),
};
