import request from '@/service';

// 获取下拉类型字典项
export async function getTypeSelect(params) {
  return request('/organization-service/dict/get_drop_down_list', {
    method: 'get',
    data: params,
  });
}

// 获取机构权限列表
export async function getAuthority(params) {
  return request('/organization-service/role/get_org_role_list', {
    method: 'get',
    data: params,
  });
}

// 获取员工已有角色
export async function getStaffAuthority(params) {
  return request('/organization-service/role/get_org_staff_role_id_list', {
    method: 'get',
    data: params,
  });
}

// 变更员工权限
export async function changeStaffAuthority(params) {
  return request('/organization-service/role/change_staff_role', {
    method: 'post',
    data: params,
  });
}

// 创建员工
export async function createStaff(params) {
  return request('/person-service/staff/create_staff', {
    method: 'post',
    data: params,
  });
}

// 修改员工信息
export async function updateStaff(params) {
  return request('/person-service/staff/update_staff_info', {
    method: 'post',
    data: params,
  });
}

// 删除员工
export async function deleteStaff(params) {
  return request('/person-service/staff/delete_staff_info', {
    method: 'delete',
    data: params,
  });
}

// 获取详情
export async function staffDetail(params) {
  return request('/person-service/staff/staff_info_by_id', {
    method: 'get',
    data: params,
  });
}

// 绑定 -- 发送验证码
export async function sendPhoneCode(params) {
  return request('/person-service/staff/send_bind_sms', {
    method: 'post',
    data: params,
  });
}

// 绑定 -- 验证码校验
export async function phoneCodeCheck(params) {
  return request('/person-service/staff/preview_bind_sms', {
    method: 'post',
    data: params,
  });
}

// 绑定 -- 确认验证码
export async function confirmBind(params) {
  return request('/person-service/staff/confirm_bind_sms', {
    method: 'post',
    data: params,
  });
}

// 解绑 -- 发送验证码
export async function unbindSendPhoneCode(params) {
  return request('/person-service/staff/send_unbind_sms', {
    method: 'post',
    data: params,
  });
}

// 解绑操作
export async function unbindConfirm(params) {
  return request('/person-service/staff/confirm_unbind_sms', {
    method: 'post',
    data: params,
  });
}
