import React from 'react';
// import PropTypes from 'prop-types';
import { Divider } from 'antd';
import RecordIcon from '@/assets/img/recordIcon.png';
import styles from './index.module.less';

const Footer = () => {

  return (
    <>
      
      <div className={styles.footerContainer}>
        <img src={RecordIcon} alt="备案" />
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11011202002143"
        >
          <div>京公网安备 11011202002143号</div>
        </a>
        <Divider type="vertical" />
        <div className={styles.recordNum}>
          备案号：<a href="http://www.beian.miit.gov.cn" rel="noopener noreferrer" target="_blank">京ICP备20007461号</a>
        </div>
        <Divider type="vertical" />
        <div className={styles.permissionCode}>增值电信业务许可证: 京B2-20201598</div>
      </div>
    </>
  );
};

Footer.defaultProps = {

};
Footer.propTypes = {

};

export default Footer;
