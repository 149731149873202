import { message } from 'antd';
import * as server from './server';

export default {
  state: {
    loading: false,
    list: [],

    coursewareSelect: [],

    createButtonLoading: false,
    deleteButtonLoading: false,
    detailLoading: false,

    refreshTime: new Date().getTime(),
  },
  reducers: {
    setState(state, payload) {
      Object.keys(payload).forEach((item) => {
        state[item] = payload[item];
      });
    },
  },
  effects: () => ({
    // 创建修改视频信息
    async createVideo(payload, rootStates, cb) {
      this.setState({
        createButtonLoading: true,
      });
      if (payload.recordVideoId) {
        const { code } = await server.updateVideo(payload);
        if (code === 0) {
          message.success('修改成功');
          cb && cb();
        }
        this.setState({
          createButtonLoading: false,
        });
      } else {
        const { code } = await server.createVideo(payload);
        if (code === 0) {
          message.success('创建成功');
          cb && cb();
        }
        this.setState({
          createButtonLoading: false,
        });
      }
    },
    // 删除视频
    async deleteVideo(payload, rootStates, cb) {
      this.setState({
        deleteButtonLoading: true,
      });
      const { code } = await server.deleteVideo(payload);
      if (code === 0) {
        message.success('删除成功');
        cb && cb();
      }
      this.setState({
        deleteButtonLoading: false,
      });
    },

    // 刷新页面
    refresh() {
      this.setState({
        refreshTime: new Date().getTime(),
      });
    },
  }),
};
