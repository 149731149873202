import React from 'react';
import { Modal, Button } from 'antd';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from '../index.module.less';

const ConfirmModal = (props) => {
  const {
    title,
    width,
    visible,
    getContainer,
    centered,
    onCancel,
    onOk,
    type,
    content,
    loading,
  } = props;

  // 关闭弹窗
  const handleCancel = () => {
    onCancel(false);
  };

  return (
    <Modal
      className={styles.confirmModal}
      getContainer={getContainer}
      visible={visible}
      title={<div />}
      width={width}
      onCancel={handleCancel}
      footer={null}
      centered={centered}
    >
      <div className={styles.header}>
        <div className={classnames(styles.icon, styles[type])} />
        <div className={styles.title}>{title}</div>
      </div>
      <div className={styles.content}>{content}</div>
      <div className={styles.footer}>
        <Button loading={loading} type="primary" onClick={onOk}>联系我
        </Button>
        <Button onClick={handleCancel}>不用了</Button>
      </div>
    </Modal>
  );
};

ConfirmModal.defaultProps = {
  title: '',
  width: 430,
  visible: false,
  onCancel: () => {},
  onOk: () => {},
  getContainer: false,
  centered: true,
  type: '',
  content: '',
  loading: false,
};
ConfirmModal.propTypes = {
  title: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  visible: PropTypes.bool,
  onCancel: PropTypes.func,
  onOk: PropTypes.func,
  getContainer: PropTypes.oneOfType([PropTypes.element, PropTypes.bool]),
  centered: PropTypes.bool,
  type: PropTypes.oneOfType(['waring', 'confirm', 'error']),
  content: PropTypes.string,
  loading: PropTypes.bool,
};

export default ConfirmModal;
