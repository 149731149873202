import React from 'react';
// import Logo from '@/assets/img/mainLogo.png';
import LoginLabel from '@/assets/img/goodclasslogo.svg';
import styles from './index.module.less';

const SiderTopIcon = () => {
  return (
    <div className={styles.siderTopIcon}>
      <img src={LoginLabel} alt="logo" />
      <span>GoodClass</span>
    </div>
  );
};

export default SiderTopIcon;
