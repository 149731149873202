import * as server from './server';

export default {
  state: {
    refreshTime: new Date().getTime(),

    CLASS_STATUS: [],
    SUBJECT: [],

    // 学生课程
    onevsoneCourseList: [],

  },
  reducers: {
    setState(state, payload) {
      Object.keys(payload).forEach((item) => {
        state[item] = payload[item];
      });
    },
  },
  effects: () => ({
    // 获取订单状态
    // 字典项调用
    async getDict(payload, rootStates, cb) {
      const { code, data } = await server.getTypeSelect(payload);
      if (code === 0) {
        this.setState({
          [payload.code]: data,
        });
      }
      cb && cb();
    },

    // 重新置值
    setShareValue(payload) {
      // console.log('payload', payload);
      this.setState({
        ...payload,
      });
    },

    // 刷新页面
    refresh() {
      this.setState({
        refreshTime: new Date().getTime(),
      });
    },

  }),
};
