import request from '@/service';

// 获取下拉类型字典项
export async function getTypeSelect(params) {
  return request('/organization-service/dict/get_drop_down_list', {
    method: 'get',
    data: params,
  });
}

// 创建赠送课时
export async function createClassHour(params) {
  return request('/trade-service/give_order_info/create_give_order_info', {
    method: 'post',
    data: params,
  });
}

// 获取课时类型
export async function getClassHourTypeList(params) {
  return request('/class-hour-service/class_hour_type_code/list_all', {
    method: 'get',
    data: params,
  });
}

// 手动扣减课时
export async function handleMinusClassHours(params) {
  return request('/class-hour-service/class_hour_info/manual_deduct', {
    method: 'post',
    data: params,
  });
}

// 修改备注
export async function update_remark(params) {
  return request('/class-hour-service/class_hour_detail/update_remark', {
    method: 'post',
    data: params,
  });
}

// 设置续费意向
export async function set_renew_intention(params) {
  return request('/person-service/student_info/set_renew_intention', {
    method: 'post',
    data: params,
  });
}

// 获取列表
export async function getNeedFillCourseIncome(data) {
  return request('/class-hour-service/fill_course_tmp/get_need_fill_course_income', { method: 'get', data });
}
// 是否显示按钮
export async function isShowButton() {
  return request('/class-hour-service/fill_course_tmp/is_show_button', { method: 'get' });
}
// 补填课程
export async function fillCourse(data) {
  return request('/class-hour-service/fill_course_tmp/fill_course', { method: 'post', data });
}