function produceOption(data) {
  const option = {
    tooltip: {
      trigger: 'axis',
    },
    grid: {
      top: '10%',
      left: '3%',
      right: '4%',
      bottom: '10%',
      containLabel: true,
    },
    xAxis: {
      type: 'category',
      // boundaryGap: false,
      data: data.xaxisData,
      axisTick: {
        show: false,
      },
      axisLine: {
        lineStyle: {
          color: 'rgba(6,6,6,0.45);',
        },
      },
    },
    yAxis: {
      type: 'value',
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      splitLine: {
        lineStyle: {
          type: 'dashed',
        },
        show: true,
      },
    },
    series: [
      {
        name: '',
        type: 'line',
        stack: '总量',
        symbolSize: 6,
        symbol: 'circle',
        data: data.dataList,
        itemStyle: {
          normal: {
            label: {
              show: true,
              color: 'rgba(0,0,0,0.65)',
            },
            // color: '#E91515',
            color: '#8483FB',
            lineStyle: {
              // color: '#E91515',
              color: '#8483FB',
            },
          },
        },
      },
    ],
  };

  return option;
}

export default produceOption;
