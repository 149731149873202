import React from 'react';
import UserInfo from './UserInfoArea';
import styles from './index.module.less';

const Header = () => {
  const tipWordStyle = {
    marginLeft: '43px',
  };
  return (
    <div className={styles.commonHeader}>
      <div className={styles.commonInfo}>
        <UserInfo tipWordStyle={tipWordStyle} />
      </div>
    </div>
  );
};

export default Header;
