import request from '@/service';

// 获取机构信息完善情况
export async function is_org_online_info_complete(params) {
  return request('/organization-service/online_shop/organization/is_org_online_info_complete', {
    method: 'get',
    data: params,
  });
}

// 打开机构线上显示
export async function open_show_online(params) {
  return request('/organization-service/online_shop/organization/open_show_online', {
    method: 'post',
    data: params,
  });
}

// 关闭机构线上显示
export async function close_show_online(params) {
  return request('/organization-service/online_shop/organization/close_show_online', {
    method: 'post',
    data: params,
  });
}

// 获取机构详情
export async function get_detail_by_id(params) {
  return request('/organization-service/online_shop/organization/get_detail_by_id', {
    method: 'get',
    data: params,
  });
}

// 更新机构基础信息
export async function update_org_base_info(params) {
  return request('/organization-service/online_shop/organization/update_org_base_info', {
    method: 'post',
    data: params,
  });
}

// 更新机构图片
export async function update_org_img(params) {
  return request('/organization-service/online_shop/organization/update_org_img', {
    method: 'post',
    data: params,
  });
}

// 更新机构荣誉图片
export async function update_org_honor_img(params) {
  return request('/organization-service/online_shop/organization/update_org_honor_img', {
    method: 'post',
    data: params,
  });
}

// 更新机构环境图片
export async function update_org_env_img(params) {
  return request('/organization-service/online_shop/organization/update_org_env_img', {
    method: 'post',
    data: params,
  });
}

// 添加线上售卖
export async function add_sell_online(params) {
  return request('/curriculum-service/online_shop/course_info/add_sell_online', {
    method: 'post',
    data: params,
  });
}

// 移除线上售卖
export async function remove_sell_online(params) {
  return request('/curriculum-service/online_shop/course_info/remove_sell_online', {
    method: 'post',
    data: params,
  });
}

// 课程下架
export async function off_shelf(params) {
  return request('/curriculum-service/online_shop/course_info/off_shelf', {
    method: 'post',
    data: params,
  });
}

// 课程上架
export async function on_shelf(params) {
  return request('/curriculum-service/online_shop/course_info/on_shelf', {
    method: 'post',
    data: params,
  });
}

// 课程详情-预览用
export async function get_course_detail_for_preview(params) {
  return request('/curriculum-service/online_shop/course_info/get_course_detail_for_preview', {
    method: 'get',
    data: params,
  });
}

// 课程详情-编辑用
export async function get_course_detail_by_id(params) {
  return request('/curriculum-service/online_shop/course_info/get_course_detail_by_id', {
    method: 'get',
    data: params,
  });
}

// 完善线上售卖信息
export async function save_online_course_info(params) {
  return request('/curriculum-service/online_shop/course_info/save_online_course_info', {
    method: 'post',
    data: params,
  });
}

// 获取机构覆盖人群
export async function get_cover_age_group(params) {
  return request('/organization-service/organization/get_cover_age_group', {
    method: 'get',
    data: params,
  });
}

// 全量线上售卖课程列表
export async function list_all_online_course_info(params) {
  return request('/curriculum-service/online_shop/course_info/list_all_online_course_info', {
    method: 'get',
    data: params,
  });
}

// 添加线上展示的机构教师
export async function add_org_teacher(params) {
  return request('/organization-service/online_shop/organization/add_org_teacher', {
    method: 'post',
    data: params,
  });
}

// 移除线上展示的机构教师
export async function remove_org_teacher(params) {
  return request('/organization-service/online_shop/organization/remove_org_teacher', {
    method: 'post',
    data: params,
  });
}
// 获取收费标准
export async function listChargeStandardByCourseId(params) {
  return request('/curriculum-service/course_info/list_charge_standard_by_course_id', {
    method: 'get',
    data: params,
  });
}