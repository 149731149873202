import React, { useEffect, useState } from 'react';
import {
  Modal,
} from 'antd';
import PropTypes from 'prop-types';
import styles from './index.module.less';
import classnames from 'classnames';

const CommonModal = (props) => {
  const {
    title,
    footer,
    children,
    width,
    labelShow,
    renderComponent,
    isCloseModal,
    changeCloseModalStatus,
    openModalCallback,
    customerNames,
    // manualApply,
  } = props;

  const [visible, setVisible] = useState(false);

  // 头部标题
  const TitleComponent = data => (
    <>
      <div className={styles.modalTitleLabel}>
        <div />
      </div>
      <div className={styles.modalTitle}>{data}</div>
    </>
  );

  const openModal = () => {
    setVisible(true);
    openModalCallback();
  };

  // 关闭弹窗
  const handleCancel = () => {
    setVisible(false);
  };

  /* eslint-disable */
  useEffect(() => {
    if (isCloseModal) {
      setVisible(false);
      if (changeCloseModalStatus) changeCloseModalStatus(false);
    }
  }, [isCloseModal]);
  /* eslint-enable */

  return (
    <>
      <span onClick={openModal}>{children}</span>
      {visible ? (
        <Modal
          className={classnames(styles.modalContainer, styles[customerNames])}
          getContainer
          visible={visible}
          title={labelShow ? TitleComponent(title) : title}
          width={width}
          // onOk={handleOk}
          onCancel={handleCancel}
          footer={footer || null}
        >
          {renderComponent}
        </Modal>
      ) : null}
    </>
  );
};

CommonModal.defaultProps = {
  title: '',
  footer: null,
  width: 520,
  children: <></>,
  labelShow: false,
  renderComponent: <></>,
  customerNames: '',
  // manualApply: false,
  openModalCallback: () => {},
};
CommonModal.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
  ]),
  footer: PropTypes.element,
  width: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  customerNames: PropTypes.string,
  children: PropTypes.element,
  labelShow: PropTypes.bool,
  renderComponent: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
  ]),
  openModalCallback: PropTypes.func,
};

export default CommonModal;
