import { message } from 'antd';
import * as server from './server';

export default {
  state: {
    loading: false,
    list: [],
    detail: {},
    teamMode: [],
    teachMode: [],
    onlineClassMode: [],
    classHourType: [],

    createButtonLoading: false,
    deleteButtonLoading: false,
    detailLoading: false,

    buttonLoading: false,

    refreshTime: new Date().getTime(),
  },
  reducers: {
    setState(state, payload) {
      Object.keys(payload).forEach((item) => {
        state[item] = payload[item];
      });
    },
  },
  effects: () => ({
    // 获取学科下拉项
    async getSelect(payload, rootStates, cb) {
      const { code, data } = await server.getSelect(payload);
      if (code === 0) {
        if (payload.code === 'TEAM_MODE') {
          this.setState({
            teamMode: data || [],
          });
        } else if (payload.code === 'TEACH_MODE') {
          this.setState({
            teachMode: data || [],
          });
        } else if (payload.code === 'ONLINE_CLASS_MODE') {
          this.setState({
            onlineClassMode: data || [],
          });
        } else if (payload.code === 'CLASS_HOUR_TYPE') {
          this.setState({
            classHourType: data || [],
          });
        }

        cb && cb();
      }
    },
    // 创建大纲
    async createOutline(payload, rootStates, cb) {
      this.setState({
        createButtonLoading: true,
      });
      if (payload.id) {
        const { code } = await server.updateOutline(payload);
        if (code === 0) {
          message.success('修改成功');
          cb && cb();
        }
        this.setState({
          createButtonLoading: false,
        });
      } else {
        const { code } = await server.createOutline(payload);
        if (code === 0) {
          message.success('创建成功');
          cb && cb();
        }
        this.setState({
          createButtonLoading: false,
        });
      }
    },
    // 删除大纲
    async deleteOutline(payload, rootStates, cb) {
      this.setState({
        deleteButtonLoading: true,
      });
      const { code } = await server.deleteOutline(payload);
      if (code === 0) {
        message.success('删除成功');
        cb && cb();
      }
      this.setState({
        deleteButtonLoading: false,
      });
    },
    // 复制大纲
    async copyOutline(payload, rootStates, cb) {
      this.setState({
        createButtonLoading: true,
      });
      const { code } = await server.copyOutline(payload);
      if (code === 0) {
        message.success('复制成功');
        cb && cb();
      }
      this.setState({
        createButtonLoading: false,
      });
    },
    // 获取大纲详情
    async getDetail(payload, rootStates, cb) {
      this.setState({
        detailLoading: true,
        detail: {},
      });
      const { code, data } = await server.getDetail(payload);
      if (code === 0) {
        this.setState({
          detail: data,
        });
        cb && cb(data);
      }
      this.setState({
        detailLoading: false,
      });
    },

    // 创建修改节点
    async createNode(payload, rootStates, cb) {
      this.setState({
        createButtonLoading: true,
      });
      if (payload.syllabusContentId) {
        if (payload.nodeType === 0) {
          const { code } = await server.updateParentNode(payload);
          if (code === 0) {
            message.success('修改成功');
            cb && cb();
          }
          this.setState({
            createButtonLoading: false,
          });
        } else if (payload.nodeType === 1) {
          const { code } = await server.updateChildNode(payload);
          if (code === 0) {
            message.success('修改成功');
            cb && cb();
          }
          this.setState({
            createButtonLoading: false,
          });
        }
      } else {
        const { code } = await server.createNode(payload);
        if (code === 0) {
          message.success('创建成功');
          cb && cb();
        }
        this.setState({
          createButtonLoading: false,
        });
      }
    },

    // 删除节点
    async deleteNode(payload, rootStates, cb) {
      this.setState({
        deleteButtonLoading: true,
      });
      if (payload.nodeType === 0) {
        payload.info.force = true;
        const { code } = await server.deleteParentNode(payload.info);
        if (code === 0) {
          message.success('删除成功');
          cb && cb();
        }
        this.setState({
          deleteButtonLoading: false,
        });
      } else if (payload.nodeType === 1) {
        const { code } = await server.deleteChildNode(payload.info);
        if (code === 0) {
          message.success('删除成功');
          cb && cb();
        }
        this.setState({
          deleteButtonLoading: false,
        });
      }
    },

    // 绑定课件
    async bindCourseware(payload, rootStates, cb) {
      this.setState({
        buttonLoading: true,
      });
      const { code } = await server.bindCourseware(payload);
      if (code === 0) {
        message.success('绑定成功');
        cb && cb();
      }
      this.setState({
        buttonLoading: false,
      });
    },

    // 解绑课件
    async unbindCourseware(payload, rootStates, cb) {
      this.setState({
        deleteButtonLoading: true,
      });
      const { code } = await server.unbindCourseware(payload);
      if (code === 0) {
        message.success('解绑成功');
        cb && cb();
      }
      this.setState({
        deleteButtonLoading: false,
      });
    },

    // 绑定视频
    async bindVideo(payload, rootStates, cb) {
      this.setState({
        buttonLoading: true,
      });
      const { code } = await server.bindVideo(payload);
      if (code === 0) {
        message.success('绑定成功');
        cb && cb();
      }
      this.setState({
        buttonLoading: false,
      });
    },

    // 解绑视频
    async unbindVideo(payload, rootStates, cb) {
      this.setState({
        deleteButtonLoading: true,
      });
      const { code } = await server.unbindVideo(payload);
      if (code === 0) {
        message.success('解绑成功');
        cb && cb();
      }
      this.setState({
        deleteButtonLoading: false,
      });
    },

    // 批量操作
    async patchUpdate(payload, rootStates, cb) {
      const { code } = await server.patchUpdate(payload);
      if (code === 0) {
        message.success('保存成功');
        cb && cb();
      }
    },

    // 刷新页面
    refresh() {
      this.setState({
        refreshTime: new Date().getTime(),
      });
    },
  }),
};
