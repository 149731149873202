import request from '@/service';

// 获取下拉类型字典项
export async function getTypeSelect(params) {
  return request('/organization-service/dict/get_drop_down_list', {
    method: 'get',
    data: params,
  });
}

// -----------------------------

// 获取课程课节列表
export async function getCourseNode(params) {
  return request('/curriculum-service/syllabus_instance/get_node_list', {
    method: 'get',
    data: params,
  });
}

// 获取课节详细信息
export async function getCourseNodeDetail(params) {
  return request('/curriculum-service/syllabus_instance/get_node_simple_info', {
    method: 'get',
    data: params,
  });
}

// 获取学生课时使用信息
export async function getCourseUseInfo(params) {
  return request(
    '/class-hour-service/class_hour_info/get_has_usable_class_hour_income_by_student',
    {
      method: 'get',
      data: params,
    },
  );
}

// 获取班级中学生的课时信息
export async function getCourseUseInfoTeamClass(params) {
  return request(
    '/curriculum-service/class_schedule/team/get_classhour_info_by_team_id',
    {
      method: 'get',
      data: params,
    },
  );
}

// 保存课程 不重复
export async function saveNorepeatCourse(type, params, returnInfo) {
  if (type === 'ONE_TO_ONE') {
    return request(
      '/curriculum-service/class_schedule/one_to_one/arrange_v2',
      {
        method: 'post',
        data: params,
        returnInfo,
      },
    );
  }
  if (type === 'TEAM_CLASS') {
    return request(
      '/curriculum-service/class_schedule/team/arrange_v2',
      {
        method: 'post',
        data: params,
        returnInfo,
      },
    );
  }
  if (type === 'OPEN_CLASS') {
    // /curriculum-service/class_schedule/open_class/arrange_without_repeat
    return request(
      '/curriculum-service/class_schedule/open_class/arrange_without_repeat',
      {
        method: 'post',
        data: params,
        returnInfo,
      },
    );
  }
}

// 保存课程 重复
export async function saveRepeatCourse(type, params) {
  if (type === 'ONE_TO_ONE') {
    return request(
      '/curriculum-service/class_schedule/one_to_one/arrange_repeat_of_week',
      {
        method: 'post',
        data: params,
      },
    );
  }
  if (type === 'TEAM_CLASS') {
    return request(
      '/curriculum-service/class_schedule/team/arrange_repeat_of_week',
      {
        method: 'post',
        data: params,
      },
    );
  }
  if (type === 'OPEN_CLASS') {
    return request(
      '/curriculum-service/class_schedule/open_class/arrange_repeat_of_week',
      {
        method: 'post',
        data: params,
      },
    );
  }
}

// 获取学生购买的课程
// export async function getCourseByStudent(params) {
//   return request('/trade-service/order_info/get_student_order_simple_info', {
//     method: 'get',
//     data: params,
//   });
// }

// 获取学生课程学习进度 1v1
export async function getOnevoneCourseProgress(params) {
  return request(
    '/curriculum-service/class_schedule/one_to_one/learning_progress',
    {
      method: 'get',
      data: params,
    },
  );
}

// 获取学生课程学习进度 小班课
export async function getLittleCourseProgress(params) {
  return request('/curriculum-service/class_schedule/team/learning_progress', {
    method: 'get',
    data: params,
  });
}

// 获取学生课程学习进度 公开课
// export async function getPublicCourseProgress(params) {
//   return request('/curriculum-service/class_schedule/open_class/learning_progress', {
//     method: 'get',
//     data: params,
//   });
// }

// 获取课程大纲实例
export async function getPublicCourseProgress(params) {
  return request(
    '/curriculum-service/syllabus_instance/get_syllabus_instance_by_course_id',
    {
      method: 'get',
      data: params,
    },
  );
}

// 1v1 获取学员信息
export async function getStudentInfo(params) {
  return request('/person-service/student_info/get_student_and_parent_info', {
    method: 'get',
    data: params,
  });
}

// 1v1 获取学员信息-新
export async function getStudentInfoNew(params) {
  return request('/person-service/student_info/get_student_simple_info', {
    method: 'get',
    data: params,
  });
}

// 取消课程
export async function cancelClass(type, params) {
  if (type === 'ONE_TO_ONE') {
    return request('/curriculum-service/class_schedule/one_to_one/cancel', {
      method: 'post',
      data: params,
    });
  }
  if (type === 'TEAM_CLASS') {
    return request('/curriculum-service/class_schedule/team/cancel', {
      method: 'post',
      data: params,
    });
  }
  if (type === 'OPEN_CLASS') {
    return request('/curriculum-service/class_schedule/open_class/cancel', {
      method: 'post',
      data: params,
    });
  }
}

// 结课
export async function finishClass(type, params) {
  if (type === 'ONE_TO_ONE') {
    return request('/curriculum-service/class_schedule/one_to_one/finish', {
      method: 'post',
      data: params,
    });
  }
  if (type === 'TEAM_CLASS') {
    return request('/curriculum-service/class_schedule/team/finish', {
      method: 'post',
      data: params,
    });
  }
  if (type === 'OPEN_CLASS') {
    return request('/curriculum-service/class_schedule/open_class/finish', {
      method: 'post',
      data: params,
    });
  }
}

// 公开课添加学生
export async function addStudent(params) {
  return request('/curriculum-service/class_schedule/open_class/add_student', {
    method: 'post',
    data: params,
  });
}

// 公开课添加班级
export async function addClassForOpenClass(params) {
  return request('/curriculum-service/class_schedule/open_class/add_team', {
    method: 'post',
    data: params,
  });
}

// 公开课取消课（学生）
export async function cancelClassForStudent(type, params) {
  if (type === 'TEAM_CLASS') {
    return request(
      '/curriculum-service/class_schedule/team/cancel_student_class',
      {
        method: 'post',
        data: params,
      },
    );
  }
  if (type === 'OPEN_CLASS') {
    return request(
      '/curriculum-service/class_schedule/open_class/cancel_student_class',
      {
        method: 'post',
        data: params,
      },
    );
  }
}

// 评价
export async function evaluateStudent(params) {
  return request('/curriculum-service/class_schedule/evaluation_class', {
    method: 'post',
    data: params,
  });
}

// 获取评价
export async function getEvaluation(params) {
  return request(
    '/curriculum-service/class_schedule/get_evaluation_by_class_id',
    {
      method: 'get',
      data: params,
    },
  );
}

// 字典项查询
export async function getDict(params) {
  return request('/organization-service/dict/get_drop_down_list', {
    method: 'get',
    data: params,
  });
}

// // 发送验证码
// export async function sendCheck(type, params) {
//   if (type === 'TEAM_CLASS') {
//     return request(
//       '/curriculum-service/class_schedule/team/send_sms_for_cancel',
//       {
//         method: 'post',
//         data: params,
//       },
//     );
//   }
//   if (type === 'OPEN_CLASS') {
//     return request(
//       '/curriculum-service/class_schedule/open_class/send_sms_for_cancel',
//       {
//         method: 'post',
//         data: params,
//       },
//     );
//   }
// }

// 重复排课预览
export async function repeatWeekPreview(params) {
  return request(
    '/curriculum-service/class_schedule/get_classes_preview_for_repeat_arrange',
    {
      method: 'post',
      data: params,
    },
  );
}

// 设置学生课前提醒状态
export async function changeNotify(params) {
  return request(
    '/curriculum-service/class_schedule/modify_send_notify_before_class',
    {
      method: 'post',
      data: params,
    },
  );
}

// 设置老师课前提醒状态
export async function changeTeacherNotify(params) {
  return request(
    '/curriculum-service/class_schedule/modify_send_notify_teacher_before_class',
    {
      method: 'post',
      data: params,
    },
  );
}

// 按月查询课程
export async function checkClassForMonth(params) {
  return request('/curriculum-service/class_schedule/get_classes_with_month', {
    method: 'get',
    data: params,
  });
}

// 获取全量教师列表 - hsc
export async function getAllTeachers() {
  return request('/person-service/teacher_info/list_all_teacher_info', {
    method: 'get',
  });
}

// 获取全量教室列表 - hsc
export async function getAllClassRooms() {
  return request('/organization-service/class_room/list_all_class_room', {
    method: 'get',
  });
}

// 按月查询每天课量 -hsc
export async function getClassRoomCountByMonth(params) {
  return request(
    '/curriculum-service/class_schedule/calendar/classroom/get_class_count_by_day_with_month',
    {
      method: 'get',
      data: params,
    },
  );
}

// 教室视图-月视图-课表
export async function get_class_list_with_month_classroom(params) {
  return request(
    '/curriculum-service/class_schedule/calendar/classroom/get_class_list_with_month',
    {
      method: 'get',
      data: params,
    },
  );
}

// 按月查询每天课量 -hsc
export async function getClassCountByMonth(params) {
  return request(
    '/curriculum-service/class_schedule/calendar/time/get_class_count_by_day_with_month',
    {
      method: 'get',
      data: params,
    },
  );
}
// 按月查询每天课量 -hsc
export async function getTeamClassCountByMonth(params) {
  return request(
    '/curriculum-service/class_schedule/calendar/team/get_class_count_by_day_with_month',
    {
      method: 'get',
      data: params,
    },
  );
}

// 班级视图-月视图-课表
export async function get_class_list_with_month_team(params) {
  return request(
    '/curriculum-service/class_schedule/calendar/team/get_class_list_with_month',
    {
      method: 'get',
      data: params,
    },
  );
}
// 按月查询每天课量 -hsc
export async function getTeacherClassCountByMonth(params) {
  return request(
    '/curriculum-service/class_schedule/calendar/teacher/get_class_count_by_day_with_month',
    {
      method: 'get',
      data: params,
    },
  );
}

// 时间视图-月视图-课表
export async function get_class_list_with_month_time(params) {
  return request(
    '/curriculum-service/class_schedule/calendar/time/get_class_list_with_month',
    {
      method: 'get',
      data: params,
    },
  );
}

// 时间视图-月视图-每天课量V2
export async function get_class_count_by_day_with_month_v2(params) {
  return request(
    '/curriculum-service/class_schedule/calendar/time/get_class_count_by_day_with_month_v2',
    {
      method: 'get',
      data: params,
    },
  );
}

// 按周查询每天课量 -hsc
export async function getClassCountByWeek(params) {
  return request(
    '/curriculum-service/class_schedule/calendar/time/get_class_count_by_day_with_week',
    {
      method: 'get',
      data: params,
    },
  );
}

// 按天查询课量 -hsc
export async function getClassCountByDay(params) {
  return request(
    '/curriculum-service/class_schedule/calendar/time/get_class_count_with_day',
    {
      method: 'get',
      data: params,
    },
  );
}

// 按周查询教室视图课表 -hsc
export async function getRoomClassByWeek(params) {
  return request(
    '/curriculum-service/class_schedule/calendar/classroom/get_class_list_with_week',
    {
      method: 'get',
      data: params,
    },
  );
}

// 按周查询班级视图课表 -hsc
export async function getTeamClassByWeek(params) {
  return request(
    '/curriculum-service/class_schedule/calendar/team/get_class_list_with_week',
    {
      method: 'get',
      data: params,
    },
  );
}

// 按周查询老师视图课表 -hsc
export async function getTeacherClassByWeek(params) {
  return request(
    '/curriculum-service/class_schedule/calendar/teacher/get_class_list_with_week',
    {
      method: 'get',
      data: params,
    },
  );
}

// 老师视图-月视图-课表
export async function get_class_list_with_month_teacher(params) {
  return request(
    '/curriculum-service/class_schedule/calendar/teacher/get_class_list_with_month',
    {
      method: 'get',
      data: params,
    },
  );
}

// 按周查询老师视图课表-天 -hsc
export async function getTeacherClassByDayFormWeek(params) {
  return request(
    '/curriculum-service/class_schedule/calendar/teacher/get_class_list_by_teacher_and_day',
    {
      method: 'get',
      data: params,
    },
  );
}

// 按周查询课表 -hsc
export async function getClassByWeek(params) {
  return request(
    '/curriculum-service/class_schedule/calendar/time/get_class_list_with_week',
    {
      method: 'get',
      data: params,
    },
  );
}


// 按天查询课程
export async function checkClassForDay(params) {
  return request(
    '/curriculum-service/class_schedule/calendar/time/get_class_list_with_day',
    {
      method: 'get',
      data: params,
    },
  );
}

// 按天查询课程-班级视图
export async function checkTeamClassForDay(params) {
  return request(
    '/curriculum-service/class_schedule/calendar/team/get_class_list_with_day',
    {
      method: 'get',
      data: params,
    },
  );
}

// 按天查询课程-老师视图
export async function checkTeacherClassForDay(params) {
  return request(
    '/curriculum-service/class_schedule/calendar/teacher/get_class_list_with_day',
    {
      method: 'get',
      data: params,
    },
  );
}
// 按天查询课程-班级视图
export async function checkRoomClassForDay(params) {
  return request(
    '/curriculum-service/class_schedule/calendar/classroom/get_class_list_with_day',
    {
      method: 'get',
      data: params,
    },
  );
}

// 获取回放url
export async function getPlaybackUrl(params) {
  return request('/curriculum-service/online_room/get_playback_url', {
    method: 'get',
    data: params,
  });
}

// 小班课添加学生
export async function addStudentForTeamclass(params) {
  return request('/curriculum-service/class_schedule/team/add_student', {
    method: 'post',
    data: params,
  });
}

// 获取录播视频数据
export async function getRecordVideoData(params) {
  return request('/curriculum-service/online_room/get_record_video_data', {
    method: 'get',
    data: params,
  });
}

// 获取播放地址
export async function getPreviewUrl(params) {
  return request('/curriculum-service/record_video_info/get_play_url', {
    method: 'get',
    data: params,
  });
}

// 获取在线教室数据
export async function getOnlineRoomData(params) {
  return request('/curriculum-service/online_room/get_online_room_data', {
    method: 'get',
    data: params,
  });
}

// 创建在线教室
export async function createOnlineRoom(params) {
  return request('/curriculum-service/online_room/create_online_room', {
    method: 'post',
    data: params,
  });
}

// 绑定视频
export async function bindVideo(params) {
  return request('/curriculum-service/class_schedule/bind_record_video', {
    method: 'post',
    data: params,
  });
}

// 1v1 重复排课预览
export async function onevsonePreview(params) {
  return request(
    '/curriculum-service/class_schedule/one_to_one/get_preview_for_repeat_arrange_v2',
    {
      method: 'post',
      data: params,
    },
  );
}

// 小班课预览
export async function teamclassPreview(params) {
  return request(
    '/curriculum-service/class_schedule/team/get_preview_for_repeat_arrange_v2',
    {
      method: 'post',
      data: params,
    },
  );
}

// 公开课预览
export async function openClassPreview(params) {
  return request(
    '/curriculum-service/class_schedule/open_class/get_preview_for_repeat_arrange',
    {
      method: 'post',
      data: params,
    },
  );
}

// 强制创建课程
export async function forceCreateOnlineRoom(params) {
  return request('/curriculum-service/online_room/create_online_room', {
    method: 'post',
    data: params,
  });
}

// 1V1签到/未到
export async function one_vs_one_sign_in(params) {
  return request('/curriculum-service/class_schedule/one_to_one/sign_in', {
    method: 'post',
    data: params,
  });
}

// 1V1免扣课时
export async function one_vs_one_no_consume_class_hour(params) {
  return request(
    '/curriculum-service/class_schedule/one_to_one/no_consume_class_hour',
    {
      method: 'post',
      data: params,
    },
  );
}

// 小班课签到/未到
export async function team_class_sign_in(params) {
  return request(
    '/curriculum-service/class_schedule/team/sign_in_student_class',
    {
      method: 'post',
      data: params,
    },
  );
}

// 小班课免扣课时
export async function team_class_no_consume_class_hour(params) {
  return request(
    '/curriculum-service/class_schedule/team/no_consume_class_hour_student_class',
    {
      method: 'post',
      data: params,
    },
  );
}

// 1V1排课详情页，顶部信息
export async function get_detail_info(params) {
  return request('/curriculum-service/class_schedule/one_to_one/get_detail_info', {
    method: 'get',
    data: params,
  });
}

// 小班课-老师课排课详情页，顶部信息
export async function get_team_teacher_class_detail(params) {
  return request('/curriculum-service/class_schedule/team/get_team_teacher_class_detail', {
    method: 'get',
    data: params,
  });
}

// 小班课-学生课排课详情页，顶部信息
export async function get_team_student_class_detail(params) {
  return request('/curriculum-service/class_schedule/team/get_team_student_class_detail', {
    method: 'get',
    data: params,
  });
}

// 获取课时类型
export async function get_class_hour_type_code(params) {
  return request('/class-hour-service/class_hour_type_code/list_all', {
    method: 'get',
    data: params,
  });
}

// 新建约课-1v1
export async function book_create_one_to_one(data) {
  return request('/curriculum-service/class_schedule/one_to_one/book_create_v2', { method: 'post', data });
}

// 新建约课-小班课
export async function book_create_team(data) {
  return request('/curriculum-service/class_schedule/team/book_create_v2', { method: 'post', data });
}

// 新建约课预览-1v1
export async function get_preview_for_repeat_book_create_one_to_one(data) {
  return request('/curriculum-service/class_schedule/one_to_one/get_preview_for_repeat_book_create_v2', { method: 'post', data });
}

// 新建约课预览-小班课
export async function get_preview_for_repeat_book_create_team(data) {
  return request('/curriculum-service/class_schedule/team/get_preview_for_repeat_book_create_v2', { method: 'post', data });
}

// 约课学员组全量列表
export async function list_all_book_student_group(data) {
  return request('/curriculum-service/class_schedule/book_student_group/list_all_book_student_group', { method: 'get', data });
}

// 新建约课学员组名称
export async function create_book_student_group(data) {
  return request('/curriculum-service/class_schedule/book_student_group/create_book_student_group', { method: 'post', data });
}

// 修改约课学员组
export async function update_book_student_group(data) {
  return request('/curriculum-service/class_schedule/book_student_group/update_book_student_group', { method: 'post', data });
}

// 修改约课学员组
export async function delete_book_student_group(data) {
  return request('/curriculum-service/class_schedule/book_student_group/delete_book_student_group', { method: 'delete', data });
}

// 全量学员列表
export async function list_student_for_book_student_group_with_page(data) {
  return request('/person-service/student_info/list_student_for_book_student_group_with_page', { method: 'get', data });
}

// 向约课学员组添加学员
export async function add_student_to_group(data) {
  return request('/curriculum-service/class_schedule/book_student_group/add_student_to_group', { method: 'post', data });
}

// 全量学员列表
export async function list_book_student_group_student_with_page(data) {
  return request('/curriculum-service/class_schedule/book_student_group/list_book_student_group_student_with_page', { method: 'get', data });
}

// 从约课学员组移除学员
export async function remove_student_from_group(data) {
  return request('/curriculum-service/class_schedule/book_student_group/remove_student_from_group', { method: 'post', data });
}

// 1v1约课学员组全量列表
export async function list_book_student_group_with_selected(data) {
  return request('/curriculum-service/class_schedule/one_to_one/list_book_student_group_with_selected', { method: 'get', data });
}

// 小班课约课学员组全量列表
export async function list_book_student_group_with_selected_team(data) {
  return request('/curriculum-service/class_schedule/team/list_book_student_group_with_selected', { method: 'get', data });
}

// 1v1修改可约学员
export async function update_book_student_group_one_to_one(data) {
  return request('/curriculum-service/class_schedule/one_to_one/update_book_student_group', { method: 'post', data });
}

// 小班课修改可约学员
export async function update_book_student_group_team(data) {
  return request('/curriculum-service/class_schedule/team/update_book_student_group', { method: 'post', data });
}

// 新建约课-1v1
export async function book_one_to_one(data) {
  return request('/curriculum-service/class_schedule/one_to_one/book', { method: 'post', data });
}

// 新建约课-小班课
export async function book_team(data) {
  return request('/curriculum-service/class_schedule/team/book', { method: 'post', data });
}

// 小班课修改可约人数
export async function update_book_max_count(data) {
  return request('/curriculum-service/class_schedule/team/update_book_max_count', { method: 'post', data });
}

// 获取小班课复制排课第一步的列表
export async function getClassListForReplicateTeam(data) {
  return request('/curriculum-service/class_schedule/team/get_class_list_for_replicate', { method: 'post', data });
}
// 获取小班课复制排课第二步的列表
export async function geneClassListForReplicateTeam(data) {
  return request('/curriculum-service/class_schedule/team/gene_class_list_for_replicate', { method: 'post', data });
}

// 获取小班课复制排课第一步的列表
export async function getClassListForReplicateOneToOne(data) {
  return request('/curriculum-service/class_schedule/one_to_one/get_class_list_for_replicate', { method: 'post', data });
}
// 获取小班课复制排课第二步的列表
export async function geneClassListForReplicateOneToOne(data) {
  return request('/curriculum-service/class_schedule/one_to_one/gene_class_list_for_replicate', { method: 'post', data });
}

// 获取小班课复制新建约课第一步的列表
export async function getClassListForReplicateBookCreate(data) {
  return request('/curriculum-service/class_schedule/team/get_class_list_for_replicate_book_create', { method: 'post', data });
}
// 获取小班课复制新建约课第二步的列表
export async function geneClassListForReplicateBookCreate(data) {
  return request('/curriculum-service/class_schedule/team/gene_class_list_for_replicate_book_create', { method: 'post', data });
}

// 获取1对1课复制新建约课第一步的列表
export async function getClassListForReplicateBookCreateOneToOne(data) {
  return request('/curriculum-service/class_schedule/one_to_one/get_class_list_for_replicate_book_create', { method: 'post', data });
}
// 获取小班课复制约课第二步的列表
export async function geneClassListForReplicateBookCreateOneToOne(data) {
  return request('/curriculum-service/class_schedule/one_to_one/gene_class_list_for_replicate_book_create', { method: 'post', data });
}

// 获取作业详情
export async function get_homework_detail(params) {
  return request('/curriculum-service/homework_info/get_homework_detail', {
    method: 'get',
    data: params,
  });
}

// 获取课的学生列表
export async function get_class_student_list(params) {
  return request('/curriculum-service/homework_info/get_class_student_list', {
    method: 'get',
    data: params,
  });
}

// 布置作业
export async function assign_homework(data) {
  return request(
    '/curriculum-service/homework_info/assign_homework',
    {
      method: 'post',
      data,
    },
  );
}

// 删除作业
export async function delete_homework(data) {
  return request(
    '/curriculum-service/homework_info/delete_homework',
    {
      method: 'delete',
      data,
    },
  );
}

// 更新作业
export async function update_homework(data) {
  return request(
    '/curriculum-service/homework_info/update_homework',
    {
      method: 'post',
      data,
    },
  );
}


// 添加需要交作业的学生
export async function add_student_for_homework(data) {
  return request(
    '/curriculum-service/homework_info/add_student_for_homework',
    {
      method: 'post',
      data,
    },
  );
}

// 移除需要交作业的学生
export async function remove_student_for_homework(data) {
  return request(
    '/curriculum-service/homework_info/remove_student_for_homework',
    {
      method: 'post',
      data,
    },
  );
}

// 设置为精选
export async function selected_student_homework(data) {
  return request(
    '/curriculum-service/homework_info/selected_student_homework',
    {
      method: 'post',
      data,
    },
  );
}

// 取消设置为精选
export async function cancel_selected_student_homework(data) {
  return request(
    '/curriculum-service/homework_info/cancel_selected_student_homework',
    {
      method: 'post',
      data,
    },
  );
}

// 点评作业
export async function review_homework(data) {
  return request(
    '/curriculum-service/homework_info/review_homework',
    {
      method: 'post',
      data,
    },
  );
}

// 编辑点评
export async function update_homework_review(data) {
  return request(
    '/curriculum-service/homework_info/update_homework_review',
    {
      method: 'post',
      data,
    },
  );
}

// 获取点评
export async function get_homework_review(params) {
  return request('/curriculum-service/homework_info/get_homework_review', {
    method: 'get',
    data: params,
  });
}

// 删除学生作业
export async function delete_student_homework_by_teacher(data) {
  return request(
    '/curriculum-service/homework_info/delete_student_homework_by_teacher',
    {
      method: 'delete',
      data,
    },
  );
}

// 获取课列表
export async function list_class_by_day_for_sign_in(params) {
  return request('/curriculum-service/class_schedule/list_class_by_day_for_sign_in', {
    method: 'get',
    data: params,
  });
}

// 获取课列表-课堂评价使用
export async function list_class_by_day_for_evaluation(params) {
  return request('/curriculum-service/class_schedule/list_class_by_day_for_evaluation', {
    method: 'get',
    data: params,
  });
}

// 获取课堂评价列表
export async function list_evaluation_by_class_id(params) {
  return request('/curriculum-service/class_evaluation/list_evaluation_by_class_id', {
    method: 'get',
    data: params,
  });
}

// 课堂评价
export async function evaluation_class(data) {
  return request('/curriculum-service/class_evaluation/evaluation_class',
    {
      method: 'post',
      data,
    });
}

// 删除课堂评价
export async function delete_class_evaluation(data) {
  return request(
    '/curriculum-service/class_evaluation/delete_class_evaluation',
    {
      method: 'delete',
      data,
    },
  );
}

// 更新课堂评价信息
export async function update_class_evaluation(data) {
  return request('/curriculum-service/class_evaluation/update_class_evaluation',
    {
      method: 'post',
      data,
    });
}

// 获取课堂评价信息
export async function get_class_evaluation(params) {
  return request('/curriculum-service/class_evaluation/get_class_evaluation', {
    method: 'get',
    data: params,
  });
}

// 获取课的学生列表
export async function get_class_student_list_evaluation(params) {
  return request('/curriculum-service/class_evaluation/get_class_student_list', {
    method: 'get',
    data: params,
  });
}

// 获取课列表
export async function list_class_by_day_for_homework(params) {
  return request('/curriculum-service/class_schedule/list_class_by_day_for_homework', {
    method: 'get',
    data: params,
  });
}
