import request from '@/service';

// 获取个人信息
export async function getUserInfo(params) {
  return request('/organization-service/account_info/info', {
    method: 'get',
    data: params,
  });
}

// 昵称变更
export async function putNickname(params) {
  // return request('/organization-service/account_info/change_nickname', {
  return request('/organization-service/account_info/change_user_name', {
    method: 'post',
    data: params,
  });
}

// 个性签名变更
export async function putSign(params) {
  return request('/organization-service/account_info/change_tips', {
    method: 'post',
    data: params,
  });
}

// 变更邮箱
export async function putMail(params) {
  return request('/organization-service/account_info/change_email', {
    method: 'post',
    data: params,
  });
}

// 发送重置密码验证码
export async function sendRestPassowrdCode(params) {
  return request('/organization-service/account_info/send_reset_password_sms_code', {
    method: 'post',
    data: params,
  });
}

// 变更密码
export async function putPassword(params) {
  return request('/organization-service/account_info/account_reset_password', {
    method: 'post',
    data: params,
  });
}

// 发送手机号重置验证码
export async function sendRestPhoneCode(params) {
  return request('/organization-service/account_info/send_reset_phone_sms_code', {
    method: 'post',
    data: params,
  });
}

// 变更手机号
export async function putPhone(params) {
  return request('/organization-service/account_info/account_reset_phone', {
    method: 'post',
    data: params,
  });
}

// 发送重置邮箱验证码
export async function sendRestMailCode(params) {
  return request('/organization-service/account_info/send_reset_mail_sms_code', {
    method: 'post',
    data: params,
  });
}

// 获取二维码
export async function getQrCode(params) {
  return request('/organization-service/account_info/generate_bind_we_chat_temp_qr', {
    method: 'get',
    data: params,
  });
}

// 轮询二维码绑定状态
export async function checkBindCode(params) {
  return request('/organization-service/account_info/polling_bind_we_chat_result', {
    method: 'get',
    data: params,
  });
}

// 取消微信绑定
export async function unbindWx(params) {
  return request('/organization-service/account_info/unbind_we_chat', {
    method: 'delete',
    data: params,
  });
}

// 清除缓存
export async function clearCache(params) {
  return request('/organization-service/cache/invalid_user_info', {
    method: 'delete',
    data: params,
  });
}

// 查询账号菜单
export async function getMenu(params) {
  return request('/organization-service/menu/get_user_menu', {
    method: 'get',
    data: params,
  });
}
