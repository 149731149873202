import { message } from 'antd';
import * as server from './server';

export default {
  state: {
    loading: false,
    list: [],
    studentDetail: {},
    studentInfo: {},

    createButtonLoading: false,
    detailLoading: false,

    refreshTime: new Date().getTime(),

    LEADS_SOURCE: [],
    LEARN_CYCLE: [],
    STUDENT_PARENT_RELATION: [],
  },
  reducers: {
    setState(state, payload) {
      Object.keys(payload).forEach((item) => {
        state[item] = payload[item];
      });
    },
  },
  effects: () => ({
    // 字典项调用
    async getDict(payload, rootStates, cb) {
      const { code, data } = await server.getTypeSelect(payload);
      if (code === 0) {
        this.setState({
          [payload.code]: data,
        });
      }
      cb && cb();
    },
    // 创建修改学生
    async createStudent(payload, rootStates, cb) {
      this.setState({
        createButtonLoading: true,
      });
      if (payload.id) {
        const { code } = await server.updateStudent(payload);
        if (code === 0) {
          message.success('修改成功');
          cb && cb();
        }
        this.setState({
          createButtonLoading: false,
        });
      } else {
        const { code } = await server.createStudent(payload);
        if (code === 0) {
          message.success('创建成功');
          cb && cb();
        }
        this.setState({
          createButtonLoading: false,
        });
      }
    },

    // 获取学生简要详情
    async getStudentInfo(payload, rootStates, cb) {
      this.setState({
        detailLoading: true,
      });
      const { code, data } = await server.getStudentInfo(payload);
      if (code === 0) {
        this.setState({
          studentInfo: data,
        });
        cb && cb(data);
      }
      this.setState({
        detailLoading: false,
      });
    },

    // 线索详情-跟进
    async follow_v2(payload, rootStates, cb) {
      this.setState({
        loading: true,
      });
      const { code, data } = await server.follow_v2(payload);
      if (code === 0) {
        // message.success('保存成功');
        cb && cb(data);
      }
      this.setState({
        loading: false,
      });
    },
    // 释放线索
    async unfollow(payload, rootStates, cb) {
      this.setState({
        loading: true,
      });
      const { code, data } = await server.unfollow(payload);
      if (code === 0) {
        // message.success('保存成功');
        cb && cb(data);
      }
      this.setState({
        loading: false,
      });
    },
    // 更新沟通内容
    async update_followup_content(payload, rootStates, cb) {
      this.setState({
        buttonLoading: true,
      });
      const { code, data } = await server.update_followup_content(payload);
      if (code === 0) {
        this.refresh();
        cb && cb(data);
      }
      this.setState({
        buttonLoading: false,
      });
    },

    // 刷新页面
    refresh() {
      this.setState({
        refreshTime: new Date().getTime(),
      });
    },
  }),
};
