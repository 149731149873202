import * as server from './server';
import openNewPage from '@/utils/openNewPage';
import { defaultPath } from '@/config/path';

export default {
  state: {
    loading: false,
    qrCodeLoading: false,
    qrCodeUrl: null,
  },
  reducers: {
    setState(state, payload) {
      Object.keys(payload).forEach((item) => {
        state[item] = payload[item];
      });
    },
  },
  effects: () => ({
    // 账号密码登录接口
    async loginByPassword(payload) {
      this.setState({
        loading: true,
      });
      const res = await server.loginByPassword(payload);
      if (res.code === 0) {
        // 存储 token
        window.localStorage.setItem('token', res.data.token);
        openNewPage(defaultPath, '_self');
      } else {
        this.setState({
          loading: false,
        });
      }
    },
    // 账号验证码登录
    async loginByCode(payload) {
      this.setState({
        loading: true,
      });
      const res = await server.loginByCode(payload);
      if (res.code === 0) {
        // 存储 token
        window.localStorage.setItem('token', res.data.token);
        openNewPage(defaultPath, '_self');
      } else {
        this.setState({
          loading: false,
        });
      }
    },
    // 注册接口
    async register(payload) {
      this.setState({
        loading: true,
      });
      const res = await server.register(payload);
      if (res.code === 0) {
        // 存储 token
        window.localStorage.setItem('token', res.data.token);
        openNewPage(defaultPath, '_self');
      } else {
        this.setState({
          loading: false,
        });
      }
    },
    // 获取登录验证码
    async getLoginCheckCode(payload, rootStates, cb) {
      const res = await server.getLoginCheckCode(payload);
      cb && cb(res);
    },
    // 获取注册验证码
    async getRegisterCheckCode(payload, rootStates, cb) {
      const res = await server.getRegisterCheckCode(payload);
      if (res.code === 0) {
        cb && cb();
      }
    },
    // 获取二维码
    async getQrCode(cb) {
      this.setState({
        qrCodeLoading: true,
      });
      const res = await server.getQrCode();
      if (res.code === 0) {
        cb && cb(res.data);
      }
      this.setState({
        qrCodeLoading: false,
      });
    },
  }),
};
