import React, { useEffect, useState } from 'react';
import {
  Route,
  Switch,
  BrowserRouter,
  Redirect,
} from 'react-router-dom';
import { connect } from 'react-redux';
import PageLoading from '@/components/PageLoading';
import { loginAndRegister } from '@/views';
import {
  hasLeftSidePath,
  noLeftSidePath,
  defaultPath,
} from '@/config/path';
import {
  MainLayout,
  DashboardLayout,
} from '@/layouts';
import {
  MainLayoutRoutes,
} from './mainLayout';
import {
  DashboardLayoutRoutes,
} from './dashboardLayout';

// console.log('init0', MainLayoutRoutes)

// window.localStorage.setItem('MainLayoutRoutes', MainLayoutRoutes);

// import Main from '../views/main/main'

const Router = (props) => {
  const {
    getUserInfo,
    getQrCode,
    getMenu,
  } = props;

  // console.log('init1', MainLayoutRoutes)

  // 重新触发请求
  const [refresh, setRefresh] = useState(true);

  // 菜单
  const [mainMenu, setMainMenu] = useState([]);

  const [loading, setLoading] = useState(false);

  /* eslint-disable */
  useEffect(() => {
    window.localStorage.removeItem('userInfo');
    window.localStorage.removeItem('currentOpen');
    // 先清空 userInfo
    if (window.localStorage.getItem('token') && refresh) {
      setRefresh(false);
      const fetchData = async () => {
        // await clearCache();
        setLoading(true);
        await getUserInfo((data) => {
          getQrCode(data);
        });
        setLoading(false);
      };
      fetchData();
    }
  }, [refresh, getUserInfo]);

  useEffect(() => {
    const fetchData = async () => {
      if (window.localStorage.getItem('orgId')) {
        await getMenu({
          orgId: window.localStorage.getItem('orgId'),
          userAccountId: window.localStorage.getItem('userId'),
        }, (data) => {
          // 系统有效期
          // data[data.length - 1].child.push({
          //   child: null,
          //   funPath: "setting/orgValidity",
          //   id: 90005,
          //   level: 2,
          //   name: "系统有效期",
          //   parentId: 90000,
          //   showSort: 90005
          // })
          // console.log('data', data);
          setMainMenu(getCurrentShowRoutes(data, MainLayoutRoutes, []));
          // console.log('ddd', data, MainLayoutRoutes);
        })
      }
    }
    fetchData();
  }, [JSON.stringify(window.localStorage.getItem('orgId'))]);
  /* eslint-enable */

  // 获取当先需展示的路由
  const getCurrentShowRoutes = (menuData, mainRoutes, arr) => {
    mainRoutes.forEach((item) => {
      menuData.forEach((res) => {
        // console.log('main', mainRoutes, item.path, res.funPath)
        if (item.path.indexOf(res.funPath) !== -1) {
          arr.push({
            ...item,
            routes: [],
          });
          if (res.child && res.child.length > 0) {
            // arr[num].routes = [];
            // console.log('res.routes', arr, res, item, item.routes)
            getCurrentShowRoutes(res.child, item.routes || [], arr[arr.length - 1].routes = [])
          } else if (item.routes && item.routes.length > 0) {
            // 处理没有子菜单的情况
            getCurrentShowRoutes([res], item.routes, arr[arr.length - 1].routes = [])
          }
        }
      })
    })
    return arr;
  };

  if (loading) {
    return <PageLoading />;
  }

  return (
    <BrowserRouter>
      <Switch>
        {/* 登录相关界面 */}
        <Route path="/login" component={loginAndRegister} />
        <Route path="/register" component={loginAndRegister} />

        {/* 主功能页面（带左侧菜单） */}
        <Route
          path={`${hasLeftSidePath}`}
          component={prop => <MainLayout {...prop} chidrenRoutes={mainMenu} />}
        />

        {/* 主功能页面（不带菜单） */}
        <Route
          path={`${noLeftSidePath}`}
          component={prop => <DashboardLayout {...prop} chidrenRoutes={DashboardLayoutRoutes} />}
        />

        {window.localStorage.getItem('token') ? <Redirect to={defaultPath} /> : <Redirect to="/login" />}

        {/* <Route exact path="/" component={loginAndRegister} /> */}

        {/* <Route exact path='/findPw' component={FindPw} /> */}
      </Switch>
    </BrowserRouter>
  );
};

export default connect(({ AccountSet }) => ({
  AccountSet,
}), (
  {
    AccountSet:
    {
      getUserInfo,
      getQrCode,
      clearCache,
      getMenu,
    },
  },
) => ({
  getUserInfo,
  getQrCode,
  clearCache,
  getMenu,
}))(Router);
