/* eslint-disable */
import axios from 'axios';
// import NProgress from 'nprogress'
import {
  message,
  notification,
} from 'antd';
import openNewPage from '@/utils/openNewPage';
// import { routerRedux } from 'dva/router';
// import { dispatch } from '../store';
const instance = axios.create();
/**
 * 功能：
 * 统一拦截http错误请求码；
 * 统一拦截业务错误代码；
 * 配置异步请求过渡状态：显示蓝色加载条表示正在请求中，避免给用户页面假死的不好体验。
 * |-- 使用 NProgress 工具库。
 */

// 设置全局参数，如响应超时时间，请求前缀等。
instance.defaults.timeout = 60000;

const token = localStorage.getItem('token');

if (token) {
  // eslint-disable-next-line dot-notation
  // instance.defaults.headers.common['Authorization'] = token;
  instance.defaults.headers.common['token'] = token;
}

switch (process.env.NODE_ENV) {
  case 'production':
    // instance.defaults.baseURL = 'http://api.goodclass.com.cn:10000';
    instance.defaults.baseURL = 'https://api.goodclass.com.cn';
    break;
  default:
    instance.defaults.baseURL = '/api/v1';
    break;
}
// axios.defaults.withCredentials = true;

// 状态码错误信息
const codeMessage = {
  200: '服务器成功返回请求的数据。',
  201: '新建或修改数据成功。',
  202: '一个请求已经进入后台排队（异步任务）。',
  204: '删除数据成功。',
  400: '发出的请求有错误，服务器没有进行新建或修改数据的操作。',
  // 401: '用户没有权限（令牌、用户名、密码错误）。',
  401: '登录超时，请重新登录',
  403: '用户得到授权，但是访问是被禁止的。',
  404: '发出的请求针对的是不存在的记录，服务器没有进行操作。',
  406: '请求的格式不可得。',
  410: '请求的资源被永久删除，且不会再得到的。',
  422: '当创建一个对象时，发生一个验证错误。',
  500: '服务器发生错误，请检查服务器。',
  502: '网关错误。',
  503: '服务不可用，服务器暂时过载或维护。',
  504: '网关超时。',
};

// 避免重复请求
let pending = []; //声明一个数组用于存储每个ajax请求的取消函数和ajax标识
let cancelToken = axios.CancelToken;
let removePending = (ever) => {
  for (let p in pending) {
    // console.log('p', pending, ever)
    if (pending[p].u === ever.url.substring(0, ever.url.indexOf('?')) + '&' + ever.method) { //当当前请求在数组中存在时执行函数体
      pending[p].f(); //执行取消操作
      pending.splice(p, 1); //把这条记录从数组中移除
    }
  }
}

// 添加一个请求拦截器，用于设置请求过渡状态
instance.interceptors.request.use((config) => {
  // 请求开始，蓝色过渡滚动条开始出现
  let orgId = window.localStorage.getItem('orgId');
  if (orgId) {
    config.headers.orgId = orgId
  }

  // --------------
  // 除了字典项以外的都过滤
  if (config.url.indexOf('dict/get_drop_down_list') === -1) {
    removePending(config); //在一个ajax发送前执行一下取消操作
    config.cancelToken = new cancelToken((c) => {
      // 不带参数的请求 url
      let noParamsUrl = config.url.substring(0, config.url.indexOf('?') === -1 ? config.url.length - 1 : config.url.indexOf('?'));
      // 这里的ajax标识我是用请求地址&请求方式拼接的字符串，当然你可以选择其他的一些方式
      pending.push({ u: noParamsUrl + '&' + config.method, f: c });
    });
  }

  // ---------------
  // 请求开始，蓝色过渡滚动条开始出现
  // NProgress.start()
  return config;
}, error => Promise.reject(error));

// 添加一个返回拦截器
instance.interceptors.response.use((response) => {
  // 请求结束，蓝色过渡滚动条消失
  // NProgress.done()
  return response;
}, (error) => {
  // 请求结束，蓝色过渡滚动条消失
  // 即使出现异常，也要调用关闭方法，否则一直处于加载状态很奇怪
  // NProgress.done()
  return Promise.reject(error);
});

export default function request(url, options) {
  const newOptions = {
    url,
    method: 'get',
    ...options,
  };
  // get 请求拼接参数
  if ((newOptions.method.toUpperCase() === 'GET' || newOptions.method.toUpperCase() === 'DELETE') && options.data) {
    newOptions.url = url
    let data = options.data;
    let parameter = '';
    Object.keys(data).forEach(item => {
      if (item) {
        const integration = `${item}=${data[item]}`;
        parameter = parameter ? `${parameter}&${integration}` : `?${integration}`;
      }
    })
    newOptions.url += parameter;
  }

  if (
    newOptions.method.toUpperCase() === 'POST'
    || newOptions.method.toUpperCase() === 'PUT'
  ) {
    if (!(newOptions.body instanceof FormData)) {
      newOptions.headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json; charset=utf-8',
        ...newOptions.headers,
      };
      newOptions.body = JSON.stringify(newOptions.body);
    } else {
      // newOptions.body is FormData
      newOptions.headers = {
        Accept: 'application/json',
        ...newOptions.headers,
      };
    }
  }

  return instance(newOptions)
    .then((response) => {
      const { data } = response;
      const { returnInfo, isDownload } = options;
      if ((data?.code === 0) || returnInfo) {
        return { ...response.data };
      } else if (data?.code === 40001 || data?.code === 40002 || data?.code === 40005) {
        message.error('登录超时，请重新登录');
        window.localStorage.clear();
        openNewPage('/', '_self');
      } else if (isDownload) {
        const { data, headers } = response;
        try {
          const fileName = decodeURIComponent(headers['content-disposition'].split('=')[1]);
          const url = window.URL.createObjectURL(new Blob([data], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"}));
          const link = document.createElement('a');
          link.style.display = 'none';
          link.href = url;
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } catch (error) {
          let enc = new TextDecoder('utf-8')
          let res = JSON.parse(enc.decode(new Uint8Array(data)))
          message.error(res.msg || '系统繁忙，请稍后再试');
        }
        
      } else {
        message.error(data.msg);
      }
      return false;
    })
    .catch((error) => {
      if (!error.response) {
        return false;
      }
      // 响应时状态码处理
      const { status } = error.response;
      const errortext = codeMessage[status] || error.response.statusText;
      notification.error({
        message: `请求错误 ${status}`,
        description: errortext,
      });

      // 存在请求，但是服务器的返回一个状态码，它们都在2xx之外
      // const { dispatch } = store;

      if (status === 401) {
        setTimeout(() => {
          window.localStorage.clear();
          openNewPage('/', '_self');
        }, 2000)
      } else if (status === 403) {
        // dispatch(routerRedux.push('/exception/403'));
      } else if (status <= 504 && status >= 500) {
        // dispatch(routerRedux.push('/exception/500'));
      } else if (status >= 404 && status < 422) {
        // dispatch(routerRedux.push('/exception/404'));
      }

      // 开发时使用，上线时删除
      // console.log(`【${newOptions.method} ${newOptions.url}】请求失败，响应数据：%o`, error.response);
      // return Promise.reject({ 'error': '401' })
      return { error: true, code: status, message: errortext };
    });
}
