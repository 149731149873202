import request from '@/service';

// 获取下拉类型字典项
export async function getTypeSelect(params) {
  return request('/organization-service/dict/get_drop_down_list', {
    method: 'get',
    data: params,
  });
}

// 获取全量教师信息列表
export async function getAllTeacher(params) {
  return request('/person-service/teacher_info/list_all_teacher_info', {
    method: 'get',
    data: params,
  });
}

// 新建教师
export async function createTeacher(params) {
  return request('/person-service/teacher_info/create_teacher_v2', {
    method: 'post',
    data: params,
  });
}

// 获取教师信息
export async function getTeacherInfo(params) {
  return request('/person-service/teacher_info/get_teacher_detail_v2', {
    method: 'get',
    data: params,
  });
}

// 修改教师信息
export async function updateTeacher(params) {
  return request('/person-service/teacher_info/update_teacher_v2', {
    method: 'post',
    data: params,
  });
}

// 删除教师信息
export async function deleteTeacher(params) {
  return request('/person-service/teacher_info/delete_teacher_info', {
    method: 'delete',
    data: params,
  });
}

// 添加教师个人经历
export async function addExperience(params) {
  return request('/person-service/teacher_person_record/create_teacher_person_record', {
    method: 'post',
    data: params,
  });
}

// 修改教师个人经历
export async function updateExperience(params) {
  return request('/person-service/teacher_person_record/update_teacher_person_record', {
    method: 'post',
    data: params,
  });
}

// 删除教师个人经历
export async function deleteExperience(params) {
  return request('/person-service/teacher_person_record/delete_teacher_person_record', {
    method: 'delete',
    data: params,
  });
}

// 添加教师个人荣誉
export async function addHonour(params) {
  return request('/person-service/teacher_info_honour_record/create_teacher_honour_record', {
    method: 'post',
    data: params,
  });
}

// 修改教师个人荣誉
export async function updateHonour(params) {
  return request('/person-service/teacher_info_honour_record/update_teacher_honour_record', {
    method: 'post',
    data: params,
  });
}

// 删除教师个人荣誉
export async function deleteHonour(params) {
  return request('/person-service/teacher_info_honour_record/delete_teacher_honour_record', {
    method: 'delete',
    data: params,
  });
}

// 绑定前检测
export async function checkBind(params) {
  return request('/person-service/teacher_info/pre_check_for_bind', {
    method: 'get',
    data: params,
  });
}

// 发送验证码
export async function sendCode(params) {
  return request('/person-service/teacher_info/send_bind_teacher_sms_code', {
    method: 'post',
    data: params,
  });
}

// 绑定
export async function bindAccount(params) {
  return request('/person-service/teacher_info/bind_teacher_account_v2', {
    method: 'post',
    data: params,
  });
}

// 获取解绑相关信息
export async function checkUnbind(params) {
  return request('/person-service/teacher_info/get_unbind_teacher_account_info', {
    method: 'get',
    data: params,
  });
}

// 发送验证码
export async function sendUnbindCode(params) {
  return request('/person-service/teacher_info/send_unbind_teacher_sms_code', {
    method: 'post',
    data: params,
  });
}

// 解绑
export async function unbindAccount(params) {
  return request('/person-service/teacher_info/unbind_teacher_account_v2', {
    method: 'post',
    data: params,
  });
}

// 获取教师标签类型
export async function getTeacherTagTypeList(params) {
  return request('/person-service/teacher_tag_code/list_all', {
    method: 'get',
    data: params,
  });
}

// 获取教师信息
export async function getDetail(params) {
  return request('/person-service/teacher_info/get_teacher_detail_v2', {
    method: 'get',
    data: params,
  });
}
// 添加关联班级
export async function addTeacherTeamBind(params) {
  return request('/person-service/teacher_info/add_teacher_team_bind', {
    method: 'post',
    data: params,
  });
}
// 取消关联班级
export async function removeTeacherTeamBind(params) {
  return request('/person-service/teacher_info/remove_teacher_team_bind', {
    method: 'post',
    data: params,
  });
}
// 取消关联学员
export async function removeTeacherStudentBind(params) {
  return request('/person-service/teacher_info/remove_teacher_student_bind', {
    method: 'post',
    data: params,
  });
}
// 添加关联学员
export async function addTeacherStudentBind(params) {
  return request('/person-service/teacher_info/add_teacher_student_bind', {
    method: 'post',
    data: params,
  });
}
